import { AxiosInstance, AxiosResponse } from 'axios';
import { ISearchResult } from '@models/SearchResult';
import { IAffiliationStatistics } from '@models/IAffiliationStatistics';
import AxiosClientBuilder, { ApiName } from '../../AxiosClientBuilder';
import IAffiliation from '../../models/IAffiliation';
import IPublication from '../../models/IPublication';
import { NodeSelection } from '../../../shared/types';

export class StatsApi {
  private static ROOT = '/stats';

  private static client: AxiosInstance = new AxiosClientBuilder()
    .setApiName(ApiName.Organizations)
    .setPrivate(true)
    .build();

  static getRandomAffiliation(): Promise<
    AxiosResponse<ISearchResult<IAffiliationStatistics | undefined>>
  > {
    return this.client.get<ISearchResult<IAffiliationStatistics>>(
      `${this.ROOT}/random`,
    );
  }

  static getAffiliation(
    id: string,
  ): Promise<AxiosResponse<ISearchResult<IAffiliationStatistics | undefined>>> {
    return this.client.get<ISearchResult<IAffiliationStatistics>>(
      `${this.ROOT}/${id}`,
    );
  }

  static getByAffiliations(
    q: string,
    recursive?: boolean,
    nodesCount?: NodeSelection,
    field:
      | 'authors'
      | 'keywords'
      | 'affiliations'
      | 'literal_name'
      | 'impact.affiliations' = 'affiliations',
    riskFilter?: string,
    impactFilter?: string,
  ): Promise<AxiosResponse<ISearchResult<IAffiliation | undefined>>> {
    return this.client.get<ISearchResult<IAffiliation>>(`${this.ROOT}/`, {
      params: { q, recursive, nodesCount, field, riskFilter, impactFilter },
    });
  }

  static searchPublications(
    affiliationId: string,
    q: string,
    from: number,
    size: number,
    publicationsSort: 'asc' | 'desc',
  ): Promise<AxiosResponse<ISearchResult<IPublication[] | undefined>>> {
    return this.client.get<ISearchResult<IPublication[]>>(
      `${this.ROOT}/publications`,
      {
        params: { affiliationId, q, from, size, publicationsSort },
      },
    );
  }

  static getSuggestedAffiliations(
    literalName: string,
  ): Promise<AxiosResponse<(IAffiliation | undefined)[]>> {
    return this.client.get<(IAffiliation | undefined)[]>(
      `${this.ROOT}/suggestions`,
      {
        params: { literalName },
      },
    );
  }
}

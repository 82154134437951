import {
  Button,
  CircularProgress,
  Fade,
  List,
  ListItemButton,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { useMemo } from 'react';
import { useRouter } from 'next/router';
import STDataAutocompleteResult from '@models/STDataAutocompleteResult';
import { HighlightedText } from '../../elements/HighlightedText';
import { SearchContext } from '../types';
import { determineRiskType } from '../../elements/RiskIndicator/functions';
import { getFields } from './AdvancedSearch';

interface Props {
  /*
   * text that was searched.
   * */
  querySearched: string;
  /*
   * autocomplete result.
   * */
  terms: STDataAutocompleteResult[];
  /*
   * control loading animation.
   * */
  onSearchClicked?: () => void;
  loading?: boolean;
  /*
   * callback that fires whenever a <ListItem/> is clicked
   * */
  onOptionClicked: (option: string) => void;
  category: SearchContext;
}

// shows autocomplete terms returned from elasticsearch
export default function AutocompleteContainer(props: Props) {
  const { querySearched, terms, loading, onOptionClicked, category } = props;

  const router = useRouter();

  return useMemo(() => {
    const performSearch = (q: string, event?, button?: boolean) => {
      event?.preventDefault();
      event?.stopPropagation();

      router.push({
        pathname: `/search/${category}`,
        query: {
          criteria: JSON.stringify([
            {
              q,
              accuracy: 'Vague',
              field:
                (category === 'web' || category === 'images') && !button
                  ? 'title'
                  : getFields(category)[0],
              operator: category === 'organizations' ? 'AND' : 'OR',
            },
          ]),
          // ...getTotalPublications(category),
          publicDemo: router.query.publicDemo,
        },
      });

      if (props.onSearchClicked) {
        props.onSearchClicked();
      }
      onOptionClicked(q);
    };

    return querySearched && querySearched.length && terms ? (
      <Paper elevation={2} square={true} sx={{ minHeight: 200 }}>
        {/* eslint-disable-next-line no-nested-ternary */}
        {!loading ? (
          terms.length > 0 ? (
            <List>
              {props.terms
                ?.slice(0, 5)
                .map((term: STDataAutocompleteResult) => (
                  <ListItemButton
                    sx={{
                      color:
                        term?.risk && term?.risk.length > 0
                          ? determineRiskType(term.risk).color
                          : null,
                    }}
                    key={term.title}
                    onClick={(event) => {
                      performSearch(term.title, event, false);
                    }}
                  >
                    <HighlightedText
                      containedText={props.querySearched}
                      text={term.title}
                    />
                  </ListItemButton>
                ))}
            </List>
          ) : (
            <Stack alignItems={'center'} sx={{ height: 200 }}>
              <Typography style={{ paddingBottom: 16, paddingTop: 16 }}>
                {"We haven't found anything for you"}
              </Typography>
            </Stack>
          )
        ) : (
          <Stack alignItems={'center'} sx={{ height: 200 }}>
            <Fade in={loading}>
              <CircularProgress />
            </Fade>
          </Stack>
        )}
        <Stack alignItems={'center'} sx={{ py: 1 }}>
          <Button
            variant={'contained'}
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();

              if (props.onSearchClicked) {
                props.onSearchClicked();
              }
              performSearch(querySearched, null, true);
            }}
          >
            Search
          </Button>
        </Stack>
      </Paper>
    ) : null;
  }, [
    loading,
    props.querySearched,
    props.terms,
    querySearched,
    terms,
    onOptionClicked,
  ]);
}

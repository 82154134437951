import { FC, useEffect, useState } from 'react';
import {
  darken,
  Menu,
  Stack,
  Typography,
  Box,
  Button,
  IconButton,
  emphasize,
} from '@mui/material';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useTheme } from '@mui/styles';
import {
  TechContextPrompt,
  WebContextPrompt,
  PublicationsContextPrompt,
  AffiliationsContextPrompt,
  PromptContext,
} from '../Prompts';
// eslint-disable-next-line import/no-cycle
import { InitialQuery } from '../ChatBotSession';
import { ButtonBase } from '../../../elements/ButtonBase';

export interface ContextDescriptionProps {
  context:
    | WebContextPrompt
    | TechContextPrompt
    | AffiliationsContextPrompt
    | PublicationsContextPrompt;
  promptContext: PromptContext;
  initialQuery: InitialQuery;
}

const getText = (
  context:
    | WebContextPrompt
    | TechContextPrompt
    | AffiliationsContextPrompt
    | PublicationsContextPrompt,
): string => {
  switch (context) {
    case 'Summarize':
      return 'Summary';
    case 'Identify.Defence-Applications':
      return 'Identify.Defence';
    case 'Summarize.Risk-Allies':
      return 'Summarize.Risk';
    case 'Identify.Defence-Applications.Risks':
      return 'Identify.Defence';
    case 'Highlight.Defence-Applications':
      return 'Highlight.Defence';
    case 'Highlight.Breakthroughs':
      return 'Breakthroughs';
    case 'Identify.Environmental.Ethical.Risk':
      return 'Identify.Risk';
    default:
      return context;
  }
};

export const ContextDescription: FC<ContextDescriptionProps> = ({
  context,
  promptContext,
  initialQuery,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const [openContent, setOpenContent] = useState<boolean>(false);

  const handleClose = () => {
    setAnchorEl(null);
    setOpenContent(false);
  };

  const theme = useTheme();

  const action = getText(context);

  const handleOpenContent = () => {
    setOpenContent(!openContent);
  };

  useEffect(() => {
    if (initialQuery?.content?.length < 200) {
      setOpenContent(true);
    }
  }, [initialQuery?.content]);

  const getTitle = (promptContext: PromptContext) => {
    switch (promptContext) {
      case 'web':
        return 'Paper';
      case 'publications':
        return 'Publication';
      case 'technologies':
        return 'Technology';
      case 'affiliations':
        return 'Organization';
      default:
        return 'Content';
    }
  };

  return (
    <Stack direction={'row'} alignItems={'center'} spacing={1}>
      <Button
        onClick={handleClick}
        size={'small'}
        sx={{
          backgroundColor: '#6409d3',
          '&:hover': { backgroundColor: darken('#6409d3', 0.2) },
          textTransform: 'none',
          py: 0,
          px: 1,
        }}
        endIcon={<QuestionMarkIcon sx={{ color: 'white', fontSize: 15 }} />}
      >
        <Typography sx={{ color: 'white' }}>{action}</Typography>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ zIndex: 4000, p: 0 }}
        MenuListProps={{
          sx: {
            py: 0,
            width: 600,
            backgroundColor: '#343540',
          },
        }}
      >
        <Stack sx={{ p: 2 }} direction={'row'} spacing={1}>
          <Box>
            <IconButton
              sx={{
                backgroundColor: '#6409d3',
                '&:hover': { backgroundColor: '#6409d3' },
              }}
            >
              <QuestionMarkIcon sx={{ color: 'white', fontSize: 15 }} />
            </IconButton>
          </Box>
          <Stack spacing={1} sx={{ color: 'white' }}>
            <Stack spacing={1}>
              <Typography sx={{ fontWeight: 'bold' }}>Question:</Typography>
              <Typography>{initialQuery?.question}</Typography>
            </Stack>
            <Stack direction={'row'} spacing={1}>
              <Typography sx={{ fontWeight: 'bold' }}>Tokens:</Typography>
              <Typography>{`${initialQuery?.tokens}/${initialQuery?.totalTokens}`}</Typography>
            </Stack>
            <Stack alignItems={'center'}>
              <ButtonBase
                sx={{
                  display: 'flex',
                  width: 50,
                  backgroundColor: theme.palette.grey[200],
                  color: theme.palette.grey[700],
                  padding: 0,
                  borderRadius: 2,
                  '&:hover, &:focus': {
                    backgroundColor: theme.palette.grey[300],
                  },
                  '&:active': {
                    boxShadow: theme.shadows[0],
                    backgroundColor: emphasize(theme.palette.grey[400], 0.12),
                  },
                }}
                onClick={handleOpenContent}
              >
                <MoreHorizIcon style={{ width: 24, height: 16 }} />
              </ButtonBase>
            </Stack>
            {openContent && (
              <Stack>
                <Typography sx={{ fontWeight: 'bold' }}>
                  {getTitle(promptContext)}
                </Typography>
                <Typography>
                  {promptContext === 'publications'
                    ? JSON.stringify(JSON.parse(initialQuery?.content), null, 2)
                    : initialQuery?.content}
                </Typography>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Menu>
    </Stack>
  );
};

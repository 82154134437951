import { useState, Fragment } from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import {
  Collapse,
  IconButton,
  IconButtonProps,
  ListItemButton,
  ListSubheader,
} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Link from './util/Link';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

interface Props {
  /*
   * defines where to pin the drawer.
   * */
  anchor: Anchor;
  /*
   * style applied to <IconButton/> that is responsible for opening the drawer.
   * */
  iconButtonProps?: IconButtonProps;
  loggedIn: Boolean;
}

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  listItemButton: {
    textTransform: 'none',
    borderRadius: 0,
    textAlign: 'start',
    width: '100%',
  },
  fullList: {
    width: 'auto',
  },
});

export default function SwipeableTemporaryDrawer(props: Props) {
  const classes = useStyles();

  const [open, setOpen] = useState<boolean>(false);

  const onClick = () => {
    setOpen(false);
  };

  const [expand, setExpand] = useState<{
    capabilities: boolean;
    tools: boolean;
  }>({ capabilities: false, tools: false });

  const handleClick = (pageName: 'capabilities' | 'tools') => {
    setExpand({ ...expand, [pageName]: !expand[pageName] });
  };

  return (
    <Fragment key={props.anchor}>
      <IconButton
        onClick={() => setOpen(true)}
        size="large"
        {...props.iconButtonProps}
      >
        <MenuIcon />
      </IconButton>
      <SwipeableDrawer
        sx={{ zIndex: 2000 }}
        anchor={props.anchor}
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
      >
        <div
          className={clsx(classes.list, {
            [classes.fullList]:
              props.anchor === 'top' || props.anchor === 'bottom',
          })}
          role="presentation"
        >
          <List
            subheader={
              <ListSubheader component="div" id="drawer-list-subheader">
                {`Menu (Logged ${props.loggedIn ? 'In' : 'Out'})`}
              </ListSubheader>
            }
          >
            {props.loggedIn ? (
              <>
                {/* @ts-ignore */}
                <Link href={'/home'}>
                  <ListItemButton onClick={onClick}>
                    <ListItemText primary={'Home'} />
                  </ListItemButton>
                </Link>
                {/* @ts-ignore */}
                <ListItemButton onClick={() => handleClick('capabilities')}>
                  <ListItemText primary={'Capabilities'} />
                  {expand.capabilities ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={expand.capabilities} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {/* @ts-ignore */}
                    <Link
                      href={'https://dataabyss.ai/marketplace'}
                      target={'_blank'}
                      rel={'noopener noreferrer'}
                    >
                      <ListItemButton
                        onClick={onClick}
                        style={{ paddingLeft: '12%' }}
                      >
                        <ListItemText primary={'Marketplace'} />
                      </ListItemButton>
                    </Link>
                    {/* @ts-ignore */}
                    <Link href={'/capabilities/elliot'}>
                      <ListItemButton
                        onClick={onClick}
                        style={{ paddingLeft: '12%' }}
                      >
                        <ListItemText primary={'Elliot'} />
                      </ListItemButton>
                    </Link>
                    {/* @ts-ignore */}
                    {/*     <Link href={'/capabilities/chatbot'}>
                      <ListItemButton
                        onClick={onClick}
                        style={{ paddingLeft: '12%' }}
                      >
                        <ListItemText primary={'Mandarin Matrix'} />
                      </ListItemButton>
                    </Link> */}
                    {/* @ts-ignore */}
                    <Link href={'/capabilities/graph'}>
                      <ListItemButton
                        onClick={onClick}
                        style={{ paddingLeft: '12%' }}
                      >
                        <ListItemText primary={'Graph'} />
                      </ListItemButton>
                    </Link>
                    {/* @ts-ignore */}
                    <Link href={'/capabilities/strategic-competition'}>
                      <ListItemButton
                        onClick={onClick}
                        style={{ paddingLeft: '12%' }}
                      >
                        <ListItemText primary={'Strategic Competition'} />
                      </ListItemButton>
                    </Link>
                    {/* @ts-ignore */}
                    <Link href={'/capabilities/affiliates'}>
                      <ListItemButton
                        onClick={onClick}
                        style={{ paddingLeft: '12%' }}
                      >
                        <ListItemText primary={'Affiliates'} />
                      </ListItemButton>
                    </Link>
                  </List>
                </Collapse>
                {/* @ts-ignore */}
                <ListItemButton onClick={() => handleClick('tools')}>
                  <ListItemText primary={'Tools'} />
                  {expand.tools ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={expand.tools} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {/* @ts-ignore */}
                    <Link href={'/tools/kibana'}>
                      <ListItemButton
                        onClick={onClick}
                        style={{ paddingLeft: '12%' }}
                      >
                        <ListItemText primary={'Kibana'} />
                      </ListItemButton>
                    </Link>
                    {/* @ts-ignore */}
                  </List>
                </Collapse>
                {/* @ts-ignore */}
                <Link
                  href={'https://dataabyss.ai/documentation'}
                  target={'_blank'}
                  rel={'noopener noreferrer'}
                >
                  <ListItemButton onClick={onClick}>
                    <ListItemText primary={'Documentation'} />
                  </ListItemButton>
                </Link>
                {/* @ts-ignore */}
                <Link
                  href={'https://dataabyss.ai/about'}
                  target={'_blank'}
                  rel={'noopener noreferrer'}
                >
                  <ListItemButton onClick={onClick}>
                    <ListItemText primary={'About Us'} />
                  </ListItemButton>
                </Link>
                {/* @ts-ignore */}
                <Link
                  href={'https://dataabyss.ai/mission'}
                  target={'_blank'}
                  rel={'noopener noreferrer'}
                >
                  <ListItemButton onClick={onClick}>
                    <ListItemText primary={'Our Mission'} />
                  </ListItemButton>
                </Link>
                {/* @ts-ignore */}
                <Link
                  href={'https://dataabyss.ai'}
                  target={'_blank'}
                  rel={'noopener noreferrer'}
                >
                  <ListItemButton onClick={onClick}>
                    <ListItemText primary={'News '} />
                  </ListItemButton>
                </Link>
                {/* @ts-ignore */}
                <Link
                  href={'https://dataabyss.ai/contact'}
                  target={'_blank'}
                  rel={'noopener noreferrer'}
                >
                  <ListItemButton onClick={onClick}>
                    <ListItemText primary={'Contact'} />
                  </ListItemButton>
                </Link>
              </>
            ) : (
              <>
                {/* @ts-ignore */}
                <Link
                  href={'https://dataabyss.ai/documentation'}
                  target={'_blank'}
                  rel={'noopener noreferrer'}
                >
                  <ListItemButton onClick={onClick}>
                    <ListItemText primary={'Documentation'} />
                  </ListItemButton>
                </Link>
                {/* @ts-ignore */}
                <Link
                  href={'https://dataabyss.ai/about'}
                  target={'_blank'}
                  rel={'noopener noreferrer'}
                >
                  <ListItemButton onClick={onClick}>
                    <ListItemText primary={'About Us'} />
                  </ListItemButton>
                </Link>
                {/* @ts-ignore */}
                {/* @ts-ignore */}
                <Link
                  href={'https://dataabyss.ai/mission'}
                  target={'_blank'}
                  rel={'noopener noreferrer'}
                >
                  <ListItemButton onClick={onClick}>
                    <ListItemText primary={'Our Mission'} />
                  </ListItemButton>
                </Link>
                {/* @ts-ignore */}
                <Link
                  href={'https://dataabyss.ai'}
                  target={'_blank'}
                  rel={'noopener noreferrer'}
                >
                  <ListItemButton onClick={onClick}>
                    <ListItemText primary={'News'} />
                  </ListItemButton>
                </Link>
                {/* @ts-ignore */}
                <Link
                  href={'https://dataabyss.ai/contact'}
                  target={'_blank'}
                  rel={'noopener noreferrer'}
                >
                  <ListItemButton onClick={onClick}>
                    <ListItemText primary={'Contact'} />
                  </ListItemButton>
                </Link>
                {/* @ts-ignore */}
                <Link href={'/auth/login'}>
                  <ListItemButton onClick={onClick}>
                    <ListItemText primary={'Login'} />
                  </ListItemButton>
                </Link>
                {/* @ts-ignore */}
                <Link href={'/auth/register?context=demo'}>
                  <ListItemButton onClick={onClick}>
                    <ListItemText primary={'Register'} />
                  </ListItemButton>
                </Link>
              </>
            )}
          </List>
        </div>
      </SwipeableDrawer>
    </Fragment>
  );
}

import { AxiosInstance } from 'axios';
import { getIdToken } from '../auth/firebase';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const axios = require('axios').default;

const AxiosClient = function (
  apiName: string,
  isPrivate = false,
  devAddress: string = null,
) {
  const axiosClient = axios.create({
    baseURL: devAddress,
  });

  axiosClient.interceptors.request.use(async (config) => {
    if (!config.headers.Authorization) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = await getIdToken();
    }

    return config;
  });

  return axiosClient;
};

export enum ApiName {
  Kibana,
  Organizations,
  Technologies,
  Publications,
  Health,
  Auth,
  Emails,
  Awards,
  Html,
  Images,
  Openai,
  Orcid,
  Elliot,
  Assistant,
}

class AxiosClientBuilder {
  private apiNames: Map<ApiName, string>;

  private selectedApiName: string;

  private isPrivate: boolean;

  private devAddress: string;

  constructor() {
    this.apiNames = this.getApiNames();
    this.selectedApiName = null;
    this.isPrivate = false;
    this.devAddress = null;
  }

  setApiName(apiName: ApiName): AxiosClientBuilder {
    this.selectedApiName = this.apiNames.get(apiName);
    return this;
  }

  setPrivate(isPrivate: boolean) {
    this.isPrivate = isPrivate;

    return this;
  }

  setDevAddress(devAddress: string) {
    this.devAddress = devAddress;

    return this;
  }

  /* TODO check env vars instead */
  build(): AxiosInstance {
    return AxiosClient(
      this.selectedApiName,
      this.isPrivate,
      process.env.NODE_ENV === 'development'
        ? `http://localhost:8081/api/${this.selectedApiName}`
        : `https://api.dataabyss.ai/api/${this.selectedApiName}`,
    );
  }

  // eslint-disable-next-line class-methods-use-this
  getApiNames(): Map<ApiName, string> {
    const apiNames = new Map<ApiName, string>();

    apiNames.set(ApiName.Kibana, 'kibana');
    apiNames.set(ApiName.Organizations, 'organizations');
    apiNames.set(ApiName.Technologies, 'technologies');
    apiNames.set(ApiName.Publications, 'publications');
    apiNames.set(ApiName.Health, 'health');
    apiNames.set(ApiName.Auth, 'auth');
    apiNames.set(ApiName.Emails, 'emails');
    apiNames.set(ApiName.Awards, 'awards');
    apiNames.set(ApiName.Html, 'html');
    apiNames.set(ApiName.Images, 'images');
    apiNames.set(ApiName.Openai, 'openai');
    apiNames.set(ApiName.Orcid, 'orcid');
    apiNames.set(ApiName.Elliot, 'elliot');

    return apiNames;
  }
}

export default AxiosClientBuilder;

import Head from 'next/head';
import Router, { useRouter } from 'next/router';
import { ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { User } from 'firebase/auth';
import { loadIcons } from '@iconify/react';
import { useMediaQuery } from '@mui/material';
import Appbar from './homepage/Appbar';
import AppNavigation from './AppNavigation';
import { listenToUserState, logout } from '../auth/firebase';
import { getCookieMeta } from '../storage/cookie-meta-storage';
import { LoadingModal } from './elements/LoadingModal';
import { GlobalSnackbar } from './elements/GlobalSnackbar';
import { GlobalDrawer } from './elements/GlobalDrawer';
import { UpgradeSnackbar } from './elements/UpgradeSnackbar';
import { ICONS } from '../constants';
import { ChatBotOverlay } from './modules/ChatBot/ChatBotOverlay';
import { ChatBotContext } from '../Context/ChatBotContext';

interface Props {
  children: ReactNode;
}

export default function Layout(props: Props) {
  const router = useRouter();

  const INFORMATIONAL_PATHS = [
    '/terms',
    '/privacy',
    '/about',
    '/platform',
    '/mission',
    '/contact',
  ];

  const EXCLUDED_PATHS = [
    '/',
    ...INFORMATIONAL_PATHS,
    '/auth/login',
    '/auth/register',
    '/auth/forgot-password',
    '/auth/user-verification',
    '/payments/products',
  ];

  const [loggedIn, setLoggedIn] = useState<boolean>(false);

  const resetSession = (
    redirectTo: '/auth/login' | '/' = '/',
    timeout?: boolean,
  ) => {
    function withTimeout() {
      return timeout ? { sessionTimeout: timeout } : {};
    }

    setLoggedIn(false);
    Router.push({ pathname: redirectTo, query: withTimeout() });
  };

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const getFaviLinks = useCallback(() => {
    const theme = prefersDarkMode ? 'dark' : 'light';

    return (
      <>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`/favicons/${theme}/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={`/favicons/${theme}/favicon-32x32.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={`/favicons/${theme}/favicon-16x16.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href={`/favicons/${theme}/android-chrome-192x192.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="512x512"
          href={`/favicons/${theme}/android-chrome-512x512.png`}
        />
        <link rel="shortcut icon" href={`/favicons/${theme}/favicon.ico`} />
        <link rel="manifest" href={`/favicons/${theme}/site.webmanifest`} />
      </>
    );
  }, [prefersDarkMode]);

  useEffect(() => {
    loadIcons(ICONS, (loaded, missing, pending) => {
      if (loaded.length) {
        return;
      }

      if (missing.length) {
        console.log(
          `Icons ${JSON.stringify(missing, null, 2)} does not exist.`,
        );
        return;
      }

      if (pending.length) {
        // Pending icons list in this example is empty.
        // If you call loadIcons() with multiple icons, pending list might not be empty, but for one icon it is always empty.
        //
        // Callback is called when something changes, with 1 icon there can only be 2 type of changes: icon has loaded or icon is missing.
      }
    });
  }, []);

  const checkForMetaCookie = async () => {
    const cookieMeta = await getCookieMeta();
    if (cookieMeta && cookieMeta.expiresAt <= Date.now()) {
      logout()
        .then(() => {
          resetSession('/auth/login', true);
        })
        .catch((e) => {
          console.error(e);
        });
    } else if (cookieMeta && cookieMeta.expiresAt > Date.now()) {
      // setLoggedIn(true);
    }
  };

  useEffect(() => {
    async function runner() {
      await checkForMetaCookie();
      setTimeout(() => {
        runner();
      }, 1000);
    }

    runner();
  }, []);

  useEffect(() => {
    listenToUserState(async (user: User) => {
      setLoggedIn(Boolean(user));
    });
  }, []);

  const { mode } = useContext(ChatBotContext);

  const getAppbar = (publicDemo = true) => {
    // rewriteRouteWithDemo(Router, publicDemo);
    if (router.pathname === '/home') {
      return (
        <AppNavigation
          rightStyle={{ sx: { mr: 22 / 8 } }}
          loggedIn={loggedIn}
          publicDemo={publicDemo}
        />
      );
    }

    if (
      (router.query?.referer !== 'home' &&
        router.pathname !== '/auth/logout' &&
        EXCLUDED_PATHS.includes(router.pathname)) ||
      router.pathname === '/terms' ||
      router.pathname === '/privacy'
    ) {
      return (
        <AppNavigation
          homepage={true}
          loggedIn={loggedIn}
          publicDemo={publicDemo}
        />
      );
    }

    return <Appbar loggedIn={loggedIn} publicDemo={!loggedIn} />;
  };

  const [mainMargin, setMainMargin] = useState<number>(0);

  return (
    <LoadingModal>
      {mode.active && <ChatBotOverlay loggedIn={loggedIn} />}
      <GlobalSnackbar>
        <GlobalDrawer
          changeMargin={{
            initMargin: 0,
            onChangeMargin: (margin) => {
              setMainMargin(margin);
            },
          }}
        >
          <div style={{ position: 'relative', minHeight: '100vh' }}>
            <Head>
              <title>Data Abyss - The Rivalry Platform</title>
              <meta
                name="viewport"
                content="minimum-scale=1, initial-scale=1, width=device-width"
              />
              {getFaviLinks()}
            </Head>
            <main style={{ minHeight: '100vh', marginRight: mainMargin }}>
              {typeof window !== 'undefined' && (
                <>
                  {![
                    '/payments/products',
                    '/auth/login',
                    '/auth/register',
                    '/auth/forgot-password',
                  ].includes(router.pathname) && getAppbar(!loggedIn)}
                  {props.children}
                </>
              )}
            </main>
            <UpgradeSnackbar
              excludedPaths={EXCLUDED_PATHS}
              loggedIn={loggedIn}
            />
          </div>
        </GlobalDrawer>
      </GlobalSnackbar>
    </LoadingModal>
  );
}

import { ChangeEvent, useEffect, useRef, useState } from 'react';
import {
  ClickAwayListener,
  Popper,
  lighten,
  darken,
  Stack,
  Chip,
  Grid,
  Container,
  Button,
  Divider,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import { useRouter } from 'next/router';
import { SearchApi as OrganizationsApi } from '@api/organizations/search';
import { SearchApi as TechnologiesApi } from '@api/technologies/search';
import { SearchApi as PublicationsApi } from '@api/publications/search';
import { SearchApi as WebApi } from '@api/web/search';
import { SearchApi as ImagesApi } from '@api/images//search';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import MemoryIcon from '@mui/icons-material/Memory';
import FeedIcon from '@mui/icons-material/Feed';
import STDataAutocompleteResult from '@models/STDataAutocompleteResult';
import { Icon } from '@iconify/react';
import { CriteriaData } from '@models/CriteriaData';
import AutocompleteContainer from './AutocompleteContainer';
import { SearchContext } from '../types';
import { AdvancedSearch, getFields } from './AdvancedSearch';
import { OpenAlexAuthorsApi } from '../../../api/openAlex/authors';

type Size = 'small' | 'large';

interface Props {
  size?: Size;
  autoFocus?: boolean;
  onSelectedContext?: (context: SearchContext) => void;
  publicDemo?: boolean;
  button?: boolean;
  flat?: boolean;
  defaultText?: string;
  onSearchDone?: () => void;
}

interface StylesProps {
  size?: Size;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.down('md')]: {
        paddingBottom: 5,
      },
    },
    popper: (stylesProps: StylesProps) => ({
      width: stylesProps.size === 'large' ? 700 : 425,
      zIndex: 2110,
    }),
  }),
);

export default function SearchInput(props: Props) {
  const classes = useStyles({ size: props.size });

  const [terms, setTerms] = useState<STDataAutocompleteResult[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [query, setQuery] = useState<string | null>('');

  const [paperElevation, setPaperElevation] = useState<number>(1);
  const [openAdvancedSearch, setOpenAdvancedSearch] = useState<boolean>(false);

  const elevatePaper = (elevate?: number) => {
    if (!openAdvancedSearch) {
      setPaperElevation(elevate || 3);
    }
  };
  const deElevatePaper = () => setPaperElevation(1);

  const popperRef = useRef(null);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [advancedAnchorEl, setAdvancedAnchorEl] = useState<null | HTMLElement>(
    null,
  );

  const openPopper = Boolean(anchorEl);
  const id = openPopper ? 'simple-popper' : undefined;

  const onMouseEnter = () => {
    if (!terms) {
      elevatePaper();
    }
  };

  const onMouseLeave = () => {
    if (!terms) {
      deElevatePaper();
    }
  };

  const inputRef = useRef();

  useEffect(() => {
    if (props.autoFocus && inputRef && inputRef.current) {
      // @ts-ignore
      inputRef.current.focus();
    }
  }, [props.autoFocus, inputRef]);

  const router = useRouter();

  useEffect(() => {
    if (router.query.q) {
      setQuery(router.query.q as string);
    }
  }, [router.query]);

  const reset = () => {
    setTerms(null);
    deElevatePaper();
    setLoading(false);
  };

  const [selectedIcon, setSelectedIcon] = useState<SearchContext>('web');

  const performAutocomplete = (query) => {
    if (query && query.length > 0) {
      elevatePaper(5);
      setAnchorEl(popperRef.current);

      switch (selectedIcon) {
        case 'organizations':
          OrganizationsApi.autocomplete(query).then((res) => {
            setLoading(false);
            setTerms(res.data.map((data) => ({ title: data, risk: null })));
          });
          break;
        case 'publications':
          PublicationsApi.autocomplete(query).then((res) => {
            setLoading(false);
            setTerms(res.data);
          });
          break;
        case 'technologies':
          TechnologiesApi.autocomplete(query).then((res) => {
            setLoading(false);
            setTerms(res.data.map((data) => ({ title: data, risk: null })));
          });
          break;
        case 'rare-technologies':
          TechnologiesApi.autocomplete(query).then((res) => {
            setLoading(false);
            setTerms(res.data.map((data) => ({ title: data, risk: null })));
          });
          break;
        case 'people':
          setLoading(false);
          setTerms(null);
          break;
        case 'open-alex-people':
          OpenAlexAuthorsApi.autocomplete(query).then((response) => {
            setTerms(
              Array.from(
                new Set(
                  response.data?.results?.map((data) => data.display_name),
                ),
              ).map((data) => ({ title: data, risk: null })),
            );
          });
          setLoading(false);
          break;
        case 'awards':
          setLoading(false);
          setTerms(null);
          break;
        case 'web':
          WebApi.autocomplete(query).then((response) => {
            setTerms(
              response.data.map((data) => ({ title: data, risk: null })),
            );
          });
          setLoading(false);
          break;
        case 'images':
          ImagesApi.autocomplete(query).then((response) => {
            setTerms(
              response.data.map((data) => ({ title: data, risk: null })),
            );
          });
          setLoading(false);
          break;
        default:
          OrganizationsApi.autocomplete(query).then((res) => {
            setLoading(false);
            setTerms(res.data.map((data) => ({ title: data, risk: null })));
          });
      }
    } else {
      setAnchorEl(null);
      reset();
    }
  };

  const onChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    event.preventDefault();
    event.stopPropagation();

    const text: string = event.target.value;

    setLoading(true);
    if (text) {
      setQuery(text);
    } else {
      setQuery('');
    }

    performAutocomplete(text);
  };

  const onClick = () => {
    performAutocomplete(query);
  };

  const selectIcon = (context: SearchContext, color: string) => {
    const style = { fontSize: 24, color, paddingLeft: 6 };

    if (props.onSelectedContext) {
      props.onSelectedContext(selectedIcon);
    }

    switch (context) {
      case 'web':
        return <Icon icon={'mdi:spider-web'} style={style} />;
      case 'images':
        return <Icon icon={'ph:image-duotone'} style={style} />;
      case 'organizations':
        return <AccountBalanceIcon style={style} />;
      case 'publications':
        return <FeedIcon style={style} />;
      case 'technologies':
        return <MemoryIcon style={style} />;
      case 'rare-technologies':
        return <Icon icon={'icon-park-outline:gemini'} style={style} />;
      case 'people':
        return <PeopleAltIcon style={style} />;
      case 'open-alex-people':
        return <Icon icon={'cil:people'} style={style} />;
      case 'awards':
        return <EmojiEventsIcon style={style} />;
      default:
        return <AccountBalanceIcon style={style} />;
    }
  };

  const getLabel = (context: SearchContext) => {
    switch (context) {
      case 'organizations':
        return 'Organizations';
      case 'publications':
        return 'Publications';
      case 'technologies':
        return 'Technologies';
      case 'rare-technologies':
        return 'Rare Technologies';
      case 'people':
        return 'Foreign Talent';
      case 'open-alex-people':
        return 'People';
      case 'awards':
        return 'Awards';
      case 'web':
        return 'Web';
      case 'images':
        return 'Images';
      default:
        return 'Search';
    }
  };

  const onAdvancedSearchClicked = (criteria: CriteriaData[]) => {
    if (props.onSearchDone) {
      props.onSearchDone();
    }
    router.push({
      pathname: `/search/${selectedIcon}`,
      query: {
        criteria: JSON.stringify(criteria),
        // ...getTotalPublications(selectedIcon),
        publicDemo: props.publicDemo,
      },
    });
  };

  const performSearch = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (query.length > 0) {
      onAdvancedSearchClicked([
        {
          field: getFields(selectedIcon)[0],
          q: query,
          operator: selectedIcon === 'organizations' ? 'AND' : 'OR',
          accuracy: 'Vague',
        },
      ]);
    }
  };

  useEffect(() => {
    setAdvancedAnchorEl(openAdvancedSearch ? popperRef.current : null);
  }, [openAdvancedSearch]);

  const getPlaceholder = () => {
    switch (selectedIcon) {
      case 'open-alex-people':
        return 'People';
      case 'people':
        return 'Foreign Talent';
      default:
        return selectedIcon.charAt(0).toUpperCase() + selectedIcon.slice(1);
    }
  };

  // @ts-ignore
  return (
    <>
      <ClickAwayListener onClickAway={reset}>
        <div
          className={classes.root}
          onKeyDown={(event) => (event.key === 'Escape' ? reset() : null)}
          tabIndex={0}
        >
          <Paper
            component="form"
            ref={popperRef}
            elevation={props.flat ? 0 : paperElevation}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            sx={{
              cursor: props.button ? 'pointer' : null,
              // eslint-disable-next-line no-nested-ternary
              width: props.flat ? '100%' : props.size === 'small' ? 350 : 700,
              // eslint-disable-next-line no-nested-ternary
              borderRadius: props.flat ? 0 : props.size === 'large' ? 30 : 5,
              sm: {
                width: `calc(100vw / ${
                  props.size === 'large' ? '1.2' : '1.5'
                })`,
                py: 2,
              },
            }}
          >
            <Stack alignItems={'center'}>
              <InputBase
                inputRef={inputRef}
                aria-describedby={id}
                value={query}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    if (props.onSearchDone) {
                      props.onSearchDone();
                    }
                    performSearch(event);
                  }
                }}
                sx={{
                  flex: 1,
                  zIndex: 10,
                  // eslint-disable-next-line no-nested-ternary
                  width: props.flat
                    ? '100%'
                    : props.size === 'small'
                    ? 350
                    : 700,
                  // eslint-disable-next-line no-nested-ternary
                  borderRadius: props.flat
                    ? 0
                    : props.size === 'large'
                    ? 30
                    : 5,
                  backgroundColor: props.size === 'small' ? '#f6f6f6' : null,
                  '& .MuiInputBase-input.Mui-disabled': {
                    WebkitTextFillColor: '#000000',
                  },
                  input: {
                    cursor: props.button ? 'pointer' : 'text',
                    // eslint-disable-next-line no-nested-ternary
                    height: props.flat ? 60 : props.size === 'small' ? 35 : 40,
                  },
                }}
                disabled={openAdvancedSearch || props.button}
                startAdornment={
                  <Stack
                    sx={{ px: 2, cursor: props.button ? 'pointer' : null }}
                    alignItems={'center'}
                  >
                    <Icon
                      icon={'iconamoon:search-thin'}
                      style={{ fontSize: 25, color: lighten('#0e63cb', 0.2) }}
                    />
                  </Stack>
                }
                endAdornment={
                  props.size === 'large' ? (
                    <Stack
                      direction={'row'}
                      sx={{
                        width: 250,
                        cursor: 'pointer',
                        mr: 2,
                        color: '#0e63cb',
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                        setOpenAdvancedSearch(!openAdvancedSearch);
                      }}
                      alignItems={'center'}
                    >
                      <Button
                        size={'small'}
                        variant={'text'}
                        sx={{
                          textTransform: 'none',
                          color: '#0e63cb',
                          width: 160,
                        }}
                        endIcon={
                          <Icon
                            icon={
                              openAdvancedSearch
                                ? 'iconoir:nav-arrow-down'
                                : 'iconoir:nav-arrow-up'
                            }
                            fontSize={25}
                          />
                        }
                      >
                        Advanced Search
                      </Button>
                    </Stack>
                  ) : null
                }
                onChange={onChange}
                onClick={onClick}
                placeholder={
                  props.defaultText
                    ? props.defaultText
                    : `Search ${getPlaceholder()}`
                }
                inputProps={{ 'aria-label': 'search data abyss' }}
              />
              {props.flat && <Divider sx={{ width: '100%' }} />}
            </Stack>
          </Paper>
          {!props.button && (
            <Popper
              sx={{
                pt: !props.flat ? 1 : 0,
                zIndex: 2110,
                width: props.size === 'large' ? 700 : 425,
              }}
              id={'advanced-search-paper'}
              open={openAdvancedSearch}
              anchorEl={advancedAnchorEl}
            >
              <AdvancedSearch
                searchContext={selectedIcon}
                onAdvancedSearchClicked={onAdvancedSearchClicked}
              />
            </Popper>
          )}
          <Popper
            className={classes.popper}
            sx={{ pt: !props.flat ? 1 : 0 }}
            id={id}
            open={openPopper && !openAdvancedSearch}
            anchorEl={anchorEl}
          >
            <AutocompleteContainer
              querySearched={query}
              terms={terms}
              loading={loading}
              onOptionClicked={reset}
              category={selectedIcon}
              onSearchClicked={props?.onSearchDone}
            />
          </Popper>
        </div>
      </ClickAwayListener>
      {!props.button && (
        <Container>
          <Grid
            container
            direction={'row'}
            sx={{ py: 2, maxWidth: 700 }}
            spacing={1}
            justifyContent={'center'}
          >
            {[
              'web',
              'images',
              'publications',
              'organizations',
              'open-alex-people',
              'people',
              'technologies',
              'rare-technologies',
              'awards',
            ].map((context: SearchContext) => (
              <Grid key={`chip-search-context${context}-grid`} item sm={3.5}>
                <Chip
                  label={getLabel(context)}
                  variant={selectedIcon === context ? 'filled' : 'outlined'}
                  sx={{
                    color: selectedIcon === context ? 'white' : null,
                    backgroundColor:
                      selectedIcon === context ? darken('#0e63cb', 0.2) : null,
                    '&:hover': {
                      backgroundColor: darken('#0e63cb', 0.3),
                    },
                  }}
                  icon={selectIcon(
                    context,
                    selectedIcon !== context
                      ? lighten('#0e63cb', 0.2)
                      : 'white',
                  )}
                  onClick={() => setSelectedIcon(context)}
                />
              </Grid>
            ))}
          </Grid>
        </Container>
      )}
    </>
  );
}

SearchInput.defaultProps = { size: 'large', autoFocus: true };

// eslint-disable-next-line no-use-before-define
import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import {
  Avatar,
  ClickAwayListener,
  darken,
  Divider,
  lighten,
  ListItemIcon,
  MenuItem,
  MenuList,
  Popper,
  ThemeProvider,
  StyledEngineProvider,
  Typography,
  adaptV4Theme,
  Box,
} from '@mui/material';
import { createTheme, Theme } from '@mui/material/styles';
import { User } from 'firebase/auth';
import { useRouter } from 'next/router';
import { Icon } from '@iconify/react';
import { Paper } from '../../elements/Paper';
import { getTextInitials } from '../../../shared/functions';
import { Profile } from './types';
import { listenToUserState, Stripe } from '../../../auth/firebase';
import { DISCO_ORANGE } from '../../../colors';
import { PlanContext } from '../../../Context/PlanContext';
import { Plan } from '../../../shared/types';
import { PlanIndicator } from './PlanIndicator';
import { useLoadingModalContext } from '../../elements/LoadingModal';
import { useGlobalSnackbarContext } from '../../elements/GlobalSnackbar';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export const ProfileMenu: FC = () => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const router = useRouter();

  const [profile, setProfile] = useState<Profile>(null);

  const theme = createTheme(
    adaptV4Theme({
      typography: {},
    }),
  );

  useEffect(() => {
    listenToUserState((user: User) => {
      if (user) {
        setProfile({ email: user.email, displayName: user.displayName });
      } else {
        setProfile(null);
      }
    });
  }, []);

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  const handleClose = (event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const plan = useContext(PlanContext);
  const { showModal, hideModal } = useLoadingModalContext();
  const { showSnackbar } = useGlobalSnackbarContext();

  const loadStripePortal = () => {
    if (plan === Plan.Demo) {
      router.push('/payments/products');
    } else {
      showModal('Loading customer portal...');
      Stripe.getCustomerPortal(router.pathname).catch((error) => {
        hideModal();
        console.log(error);
        showSnackbar({
          severity: 'error',
          message: "Couldn't communicate successfully with Stripe",
        });
      });
    }
  };

  const MENU_ITEM_STYLE = { ml: 1 };

  return (
    <div>
      <Box
        sx={{
          cursor: 'pointer',
          borderRadius: 360,
          border: 'solid',
          borderColor: open ? '#d9d9d9' : 'transparent',
          borderWidth: 4,
          '&:hover': {
            borderColor: '#d9d9d9',
          },
        }}
      >
        <Avatar
          sx={{
            color: 'white',
            backgroundColor: darken(theme.palette.secondary.dark, 0.2),
            '&:hover': {
              backgroundColor: darken(theme.palette.secondary.dark, 0.28),
            },
          }}
          ref={anchorRef}
          onClick={() => setOpen(!open)}
        >
          {getTextInitials(profile?.displayName)}
        </Avatar>
      </Box>
      <Popper
        style={{ zIndex: 1 }}
        open={open}
        anchorEl={anchorRef.current}
        placement="top-end"
        disablePortal
      >
        <Paper
          style={{
            backgroundColor: lighten(theme.palette.background.default, 0.4),
            minWidth: 200,
            marginTop: 8,
          }}
          elevation={4}
        >
          <ClickAwayListener onClickAway={handleClose}>
            <MenuList
              autoFocusItem={open}
              onKeyDown={handleListKeyDown}
              style={{
                minWidth: 120,
              }}
            >
              <div
                style={{
                  textAlign: 'center',
                  paddingLeft: 12,
                  paddingRight: 12,
                  paddingTop: 8,
                  paddingBottom: 12,
                }}
              >
                <Typography variant={'h5'} style={{ fontSize: '1.3em' }}>
                  {profile?.displayName}
                </Typography>
                <Typography
                  variant={'subtitle1'}
                  style={{ fontSize: '1.1em', color: 'grey' }}
                >
                  {profile?.email}
                </Typography>
                <PlanIndicator currentPlan={plan} />
              </div>
              <Divider variant={'middle'} style={{ marginBottom: 4 }} />
              {plan !== Plan.Premium && (
                <MenuItem
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    color: '#390c5e',
                    backgroundColor: theme.palette.background.default,
                    '&:hover': {
                      color: 'black',
                      backgroundColor: DISCO_ORANGE,
                    },
                  }}
                  onClick={() => {
                    loadStripePortal();
                  }}
                >
                  <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={theme}>
                      <Typography sx={{ textAlign: 'center' }}>
                        UPGRADE
                      </Typography>
                    </ThemeProvider>
                  </StyledEngineProvider>
                </MenuItem>
              )}
              {plan !== Plan.Demo && (
                <MenuItem
                  onClick={() => {
                    loadStripePortal();
                  }}
                >
                  <ListItemIcon>
                    <Icon icon="fa-brands:cc-stripe" style={{ fontSize: 30 }} />
                  </ListItemIcon>
                  <Typography variant={'body1'} sx={MENU_ITEM_STYLE}>
                    Customer Portal
                  </Typography>
                </MenuItem>
              )}
              <MenuItem
                onClick={() => {
                  router.push('/auth/logout');
                }}
              >
                <ListItemIcon>
                  <Icon
                    icon="clarity:logout-solid"
                    style={{ fontSize: 30, marginRight: 8 }}
                  />
                </ListItemIcon>
                <Typography variant={'body1'} sx={MENU_ITEM_STYLE}>
                  Logout
                </Typography>
              </MenuItem>
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </div>
  );
};

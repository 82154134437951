import Link from './util/Link';

export interface BorderlessLinkButtonProps {
  /*
   * children wrapped by <Link/>
   * */
  children?: React.ReactNode;
  /*
   * href that is passed to ./util/Link
   * */
  href?: string;
  markColor?: string;
  withPadding?: boolean;
  external?: boolean;
}

export default function BorderlessLinkButton(props: BorderlessLinkButtonProps) {
  const { children, href, external } = props;

  // using inline style instead of passing prop to useStyles because doing so seems to break the ui in dev mode.
  return (
    /* @ts-ignore */
    <Link
      href={href}
      target={external ? '_blank' : null}
      rel={external ? 'noopener noreferrer' : null}
    >
      <div
        style={{
          padding: props.withPadding ? 12 : 0,
        }}
      >
        {children}
      </div>
    </Link>
  );
}

const defaultProps: BorderlessLinkButtonProps = {
  children: undefined,
  href: '',
};

BorderlessLinkButton.defaultProps = defaultProps;

import { FC } from 'react';
import { Box, Stack, TextField } from '@mui/material';
import { Accuracy, Field } from '@models/CriteriaData';
import { FieldsMenu } from './FieldsMenu';
import { LogicalOperator, OperatorMenu } from './OperatorMenu';

export type FilterType = 'search' | 'select' | 'number';

export interface CriteriaSelectorProps {
  options?: { fields: Field[] };
  onQueryChange?(q: string): void;
  onOperatorSelected?: (logicalOperator: LogicalOperator) => void;
  onFieldSelected?: (field: Field) => void;
  onAccuracySelected?: (accuracy: Accuracy) => void;
  defaultOptionPosition?: number;
  withLogicalOperator?: boolean;
  autoFocus?: boolean;
  error?: boolean;
  inputWidth?: number | string;
  value?: string;
  handleKeyDown?: (event: React.KeyboardEvent) => void;
  withStartEmptySpace?: boolean;
  withAccuracySelector?: boolean;
  filterType?: FilterType;
  filterSelectTypeList?: string[];
  onFieldOptionSelected?: (option: string) => void;
  disabledOperators?: LogicalOperator[];
}

export const CriteriaSelector: FC<CriteriaSelectorProps> = ({
  options,
  onQueryChange,
  withLogicalOperator,
  onFieldSelected,
  onAccuracySelected,
  defaultOptionPosition,
  onOperatorSelected,
  autoFocus,
  error,
  inputWidth,
  value,
  handleKeyDown,
  withStartEmptySpace = true,
  withAccuracySelector = true,
  filterType = 'search',
  filterSelectTypeList,
  onFieldOptionSelected,
  disabledOperators,
}) => (
  <Stack direction={'row'}>
    {withLogicalOperator ? (
      <OperatorMenu
        onOperatorSelected={onOperatorSelected}
        disabledOperators={disabledOperators}
      />
    ) : (
      <Box sx={{ width: withStartEmptySpace ? 86 : 0 }} />
    )}
    <FieldsMenu
      fields={options.fields}
      onFieldSelected={onFieldSelected}
      defaultFieldPosition={defaultOptionPosition}
      width={withAccuracySelector ? null : 230}
    />
    {withAccuracySelector && (
      <FieldsMenu
        fields={['Vague', 'Accurate']}
        onFieldSelected={onAccuracySelected}
      />
    )}
    {filterType === 'search' && (
      <TextField
        value={value}
        placeholder={'Enter search criteria...'}
        error={error}
        size={'small'}
        autoFocus={autoFocus}
        onKeyDown={handleKeyDown}
        sx={{
          '& .MuiInputBase-root': {
            '&:hover fieldset': {
              borderColor: '#631da8',
            },
          },
        }}
        onChange={(e) => onQueryChange(e.target.value)}
        InputProps={{
          sx: {
            borderRadius: 0,
            width: inputWidth || 250,
            height: 45,
          },
        }}
      />
    )}
    {filterType === 'select' && (
      <FieldsMenu
        withChange
        fields={filterSelectTypeList}
        onFieldSelected={onFieldOptionSelected}
        defaultFieldPosition={defaultOptionPosition}
        width={250}
      />
    )}
    {filterType === 'number' && (
      <FieldsMenu
        withChange
        fields={filterSelectTypeList}
        onFieldSelected={onFieldOptionSelected}
        defaultFieldPosition={defaultOptionPosition}
        width={250}
      />
    )}
  </Stack>
);

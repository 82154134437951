import { AxiosInstance, AxiosResponse } from 'axios';
import { ClusterHealthResponse } from '@models/EsTypes';
import AxiosClientBuilder, { ApiName } from '../../AxiosClientBuilder';

export class EsHealthApi {
  private static ROOT = '/es';

  private static searchClient: AxiosInstance = new AxiosClientBuilder()
    .setApiName(ApiName.Health)
    .setPrivate(true)
    .build();

  static getEsHealth(): Promise<AxiosResponse<ClusterHealthResponse>> {
    return this.searchClient.get<ClusterHealthResponse>(`${this.ROOT}`);
  }
}

import { FC, useState } from 'react';
import { Icon } from '@iconify/react';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useRouter } from 'next/router';
import { Plan } from '../../../shared/types';
import { LoadingModal, useLoadingModalContext } from '../LoadingModal';
import { Stripe } from '../../../auth/firebase';
import { useGlobalSnackbarContext } from '../GlobalSnackbar';
import { usePlanUpgrade } from '../../../hooks/usePlanUpgrade';

interface AccessRestrictorProps {
  restrict: boolean;
  currentPlan: Plan;
  upgradeTo?: Plan;
  publicDemo?: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      cursor: 'pointer',
    },
    icon: {
      fontSize: 30,
      color: 'black',
    },
  }),
);

export const AccessRestrictor: FC<AccessRestrictorProps> = ({
  children,
  restrict = false,
  currentPlan,
  upgradeTo = Plan.Premium,
  publicDemo,
}) => {
  const classes = useStyles();
  const [hovered, setHovered] = useState<boolean>(false);

  const LockedIcon = () => (
    <Icon icon={'ant-design:lock-twotone'} className={classes.icon} />
  );

  const UnlockedIcon = () => (
    <Icon icon={'ant-design:unlock-twotone'} className={classes.icon} />
  );

  const router = useRouter();

  const { showModal, hideModal } = useLoadingModalContext();
  const { showSnackbar } = useGlobalSnackbarContext();

  const planUpgrade = usePlanUpgrade(upgradeTo);

  const loadStripePortal = () => {
    if (currentPlan === Plan.Demo) {
      router.push('/payments/products');
    } else {
      showModal('Loading Customer Portal...');
      Stripe.getCustomerPortal(router.pathname).catch(() => {
        hideModal();
        showSnackbar({
          severity: 'error',
          message: "Couldn't communicate successfully with Stripe",
        });
      });
    }
  };

  return (
    <div
      onClick={() => {
        if (publicDemo) {
          router.push('/auth/register', { query: { context: 'demo' } });
        } else {
          loadStripePortal();
        }
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      className={classes.root}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: restrict ? 'center' : null,
          pointerEvents: restrict ? 'none' : null,
        }}
        onClick={(e) => {
          if (!restrict) {
            e.stopPropagation();
          }
        }}
      >
        {children}
        {restrict && (hovered ? <UnlockedIcon /> : <LockedIcon />)}
      </div>
      <LoadingModal />
    </div>
  );
};

import { FC } from 'react';
import { lighten, MenuItem, Typography } from '@mui/material';
import { Icon } from '@iconify/react';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Plan } from '../../../../shared/types';
import { capitalizeFirstLetter } from '../../../../shared/functions';

interface PlanIndicatorProps {
  currentPlan: Plan;
}

const useStyles = makeStyles(() =>
  createStyles({
    menuItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 4,
      pointerEvents: 'none',
    },
    typography: {
      textAlign: 'center',
      fontSize: '1.2em',
    },
  }),
);

export const PlanIndicator: FC<PlanIndicatorProps> = ({ currentPlan }) => {
  const classes = useStyles();

  const getIconName = (): string => {
    switch (currentPlan) {
      case Plan.Premium:
        return 'fluent:premium-24-filled';
      case Plan.Standard:
        return 'carbon:carbon-for-ibm-product';
      case Plan.Demo:
        return 'ic:twotone-smart-toy';
      default:
        return 'ic:twotone-smart-toy';
    }
  };

  return (
    <MenuItem className={classes.menuItem}>
      <Icon
        icon={getIconName()}
        style={{ fontSize: 30, color: lighten('#341f57', 0.15) }}
      />
      <Typography variant={'body1'} className={classes.typography}>
        {capitalizeFirstLetter(currentPlan.toString())}
      </Typography>
    </MenuItem>
  );
};

import React, { FC, useEffect, useState } from 'react';
import {
  Button,
  darken,
  IconButton,
  Stack,
  Typography,
  TypographyProps,
} from '@mui/material';
import { Icon } from '@iconify/react';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { getIdToken } from '../../../../auth/firebase';
import {
  AffiliationsContextPrompt,
  getPrompts,
  PromptContext,
  TechContextPrompt,
  WebContextPrompt,
  PublicationsContextPrompt,
} from '../Prompts';
import { Loading } from '../../../elements/Loading';
// eslint-disable-next-line import/no-cycle
import { Session } from '../ChatBotOverlay';
import { DARK_GREY } from '../../../../colors';
// eslint-disable-next-line import/no-cycle
import { ContextDescription } from '../ContextDescription';
import { Highlighter } from '../Highlighter';
// eslint-disable-next-line import/no-cycle

export type Role = 'user' | 'system' | 'assistant' | 'function';

export type ChatBotPrompt = {
  context: PromptContext;
  content: string;
  role: Role;
  action:
    | WebContextPrompt
    | TechContextPrompt
    | AffiliationsContextPrompt
    | PublicationsContextPrompt;
  concise?: boolean;
  regenerate?: boolean;
  maxTokens?: string;
};

export interface ChatBotMessageProps {
  session: Session;
  onUpdate: () => void;
  currentPrompt:
    | WebContextPrompt
    | TechContextPrompt
    | AffiliationsContextPrompt
    | PublicationsContextPrompt;
  newSessionSelected: (session: Session) => void;
  searchTerms?: string[];
  concise?: boolean;
  fullscreen?: boolean;
  sx?: TypographyProps['sx'];
  isAuthorized?: (authorized: boolean) => void;
  tokens?: string;
}

const getIcon = (promptContext: PromptContext) => {
  switch (promptContext) {
    case 'web':
      return 'mdi:spider-web';
    case 'technologies':
      return 'material-symbols:memory';
    case 'publications':
      return 'material-symbols:feed';
    case 'affiliations':
      return 'ic:sharp-account-balance';
    default:
      return 'mdi:spider-web';
  }
};

export type InitialQuery = {
  question: string;
  content: string;
  totalTokens: number;
  tokens: number;
  regenerated?: boolean;
};

export const ChatBotSession: FC<ChatBotMessageProps> = ({
  session,
  concise = true,
  currentPrompt,
  newSessionSelected,
  searchTerms = [],
  onUpdate,
  isAuthorized,
  tokens,
}) => {
  const [value, setValue] = useState<string>('');
  const [loading, setLoading] = useState(false);

  const [prompts, setPrompts] = useState<ChatBotPrompt[]>([]);
  const [wasRegenerated, setWasRegenerate] = useState(false);
  const [unauthorized, setUnauthorized] = useState(false);

  const [initialQuery, setInitialQuery] = useState<InitialQuery>();

  useEffect(() => {
    if (session) {
      setPrompts([
        {
          content: session.question,
          context: session.context,
          role: session.role,
          action: session.type,
          concise,
          maxTokens: tokens,
        },
      ]);
    }
  }, [concise, session]);

  useEffect(() => {
    onUpdate();
  }, [onUpdate, value]);

  const isCorrectType = () =>
    session.type !== currentPrompt &&
    currentPrompt &&
    // @ts-ignore
    getPrompts(session.context).includes(currentPrompt);

  const [forceRegenerate, setForceRegenerate] = useState<boolean>(true);

  useEffect(() => {
    async function generateResponse() {
      setLoading(true);
      const token = await getIdToken();
      try {
        const response = await fetch(
          process.env.NODE_ENV === 'development'
            ? `http://localhost:8081/api/openai/chat`
            : `https://api.dataabyss.ai/api/openai/chat`,
          {
            method: 'POST',
            body: JSON.stringify(prompts),
            headers: {
              Authorization: token as string,
              'Content-Type': 'application/json',
            },
          },
        );

        if (response.ok) {
          setForceRegenerate(false);
          setLoading(false);
          const reader = response.body
            .pipeThrough(new TextDecoderStream())
            .getReader();

          while (true) {
            const { value, done } = await reader.read();
            if (done) {
              break;
            }

            try {
              const splittedValue = value.split('|-|-|');
              if (splittedValue?.length > 1) {
                const msg = JSON.parse(splittedValue[0]) as InitialQuery;

                if (!msg?.regenerated) {
                  setInitialQuery(msg);
                }

                setValue((prev) => prev + splittedValue[1]);
              } else {
                throw new Error('Not JSON');
              }
            } catch (e) {
              setValue((prev) => prev + value);
              setForceRegenerate(false);
            }
          }
        } else if (response.status === 401) {
          setForceRegenerate(false);
          setLoading(false);
          setUnauthorized(true);
        }
      } catch (e) {
        // TODO - handle error
        console.log(e);
      }
    }

    if (prompts.length > 0) {
      generateResponse();
    }
  }, [prompts]);

  const regenerateResponse = async () => {
    setValue('');
    setWasRegenerate(true);
    if (!forceRegenerate) {
      setPrompts([
        ...prompts,
        {
          content: ',',
          context: session.context,
          role: 'user',
          action: session.type,
          concise: true,
          maxTokens: tokens,
          regenerate: true,
        },
      ]);
    } else {
      setPrompts([{ ...prompts[0], maxTokens: tokens }]);
    }
  };

  useEffect(() => {
    setForceRegenerate(true);
  }, [tokens]);

  useEffect(() => {
    isAuthorized(!unauthorized);
  }, [unauthorized]);

  if (unauthorized) {
    return null;
  }

  return loading ? (
    <Stack alignItems={'center'} sx={{ pt: 5 }}>
      <Loading sx={{ color: 'white' }} />
    </Stack>
  ) : (
    <>
      <Stack
        direction={'row'}
        spacing={1}
        justifyContent={'space-between'}
        alignItems={'center'}
        sx={{
          py: 0.5,
          px: 1,
          borderBottom: '2px solid',
          backgroundColor: DARK_GREY,
          borderColor: '#eaeaea',
          mb: 1,
        }}
      >
        <Stack
          direction={'row'}
          spacing={1}
          sx={{ width: '100%' }}
          alignItems={'center'}
        >
          <Icon
            icon={getIcon(session.context)}
            style={{
              color: isCorrectType() ? 'white' : 'grey',
              width: 25,
              height: 25,
            }}
          />
          <Stack>
            <Button
              onClick={() => {
                if (isCorrectType()) {
                  newSessionSelected({
                    question: session.question,
                    type: currentPrompt,
                    context: session.context,
                    title: session.title,
                    role: 'user',
                  });
                }
              }}
              endIcon={
                isCorrectType() && (
                  <Icon icon="mingcute:openai-line" color={'white'} />
                )
              }
              sx={{
                textTransform: 'none',
                backgroundColor: isCorrectType() ? '#6409d3' : null,
                '&:hover': {
                  backgroundColor: isCorrectType()
                    ? darken('#6409d3', 0.2)
                    : null,
                },
                py: 0,
                px: 0.5,
                textAlign: 'left',
              }}
            >
              <Typography sx={{ color: 'white', width: '100%' }}>
                {session.title}
              </Typography>
            </Button>
            {wasRegenerated && (
              <Typography sx={{ color: '#820eff', fontWeight: 'bold' }}>
                Regenerated
              </Typography>
            )}
          </Stack>
        </Stack>
        <Stack direction={'row'} spacing={1} sx={{ height: '100%' }}>
          <IconButton
            onClick={regenerateResponse}
            sx={{
              textTransform: 'none',
              color: 'white',
              '&:hover': {
                backgroundColor: darken(DARK_GREY, 0.2),
              },
            }}
          >
            <AutorenewIcon />
          </IconButton>
          <ContextDescription
            context={session.type}
            promptContext={session.context}
            initialQuery={initialQuery}
          />
        </Stack>
      </Stack>
      <Highlighter text={value} search={searchTerms} />
    </>
  );
};

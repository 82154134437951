import { FC, ReactNode, useEffect, useState } from 'react';
import { Box, Button, Stack } from '@mui/material';
import { HighlightedText } from '../HighlightedText';

interface MoreOrLessTextProps {
  text?: string;
  highlightText?: string;
  color?: string;
  textSize?: string | number;
  showTextByDefault?: boolean;
  initTextLength?: number;
  moreLessButton?: ReactNode;
  textColor?: string;
}

export const MoreOrLessText: FC<MoreOrLessTextProps> = ({
  text,
  highlightText,
  color,
  textSize,
  showTextByDefault = false,
  initTextLength = 250,
  moreLessButton,
  textColor,
}) => {
  const [showText, setShowText] = useState<boolean>(true);

  useEffect(() => {
    if (showTextByDefault) {
      setShowText(false);
    }
  }, [showTextByDefault]);

  return (
    <Stack
      direction={moreLessButton ? 'row' : 'column'}
      sx={{ display: 'inline' }}
    >
      <HighlightedText
        textSize={textSize}
        containedText={highlightText}
        color={color || '#f5e09c'}
        textColor={textColor}
        text={`${text?.substring(0, showText ? initTextLength : text?.length)}${
          text && showText && !moreLessButton ? '...' : ''
        }`}
      />
      {!showTextByDefault && (
        <span
          onClick={(event) => {
            event.stopPropagation();
            setShowText(!showText);
          }}
        >
          {text?.length >= initTextLength && (
            <>
              {moreLessButton || (
                <Button variant={'text'} sx={{ color: '#5fbdf5' }}>
                  {showText ? 'More' : 'Less'}
                </Button>
              )}
            </>
          )}
        </span>
      )}
    </Stack>
  );
};

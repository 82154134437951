import { useRouter } from 'next/router';
import { Plan } from '../shared/types';
import { useLoadingModalContext } from '../components/elements/LoadingModal';
import { useGlobalSnackbarContext } from '../components/elements/GlobalSnackbar';
import { Stripe } from '../auth/firebase';

export function usePlanUpgrade(upgradeTo: Plan) {
  const { showModal, hideModal } = useLoadingModalContext();
  const { showSnackbar } = useGlobalSnackbarContext();

  const router = useRouter();

  return async () => {
    if (upgradeTo === Plan.Premium) {
      showModal('Redirecting to product page...');
      try {
        const products = await Stripe.getProducts();

        const foundProduct = products
          .filter((product) => product.prices?.length > 0)
          .find((product) => product.role === upgradeTo.toString());

        if (foundProduct) {
          await Stripe.createCheckoutSession(
            foundProduct.role,
            foundProduct.prices[0]?.id,
            null,
            false,
            router.asPath,
            '/tools/kibana',
          );
        }
      } catch (e) {
        hideModal();
        showSnackbar({
          message: "Couldn't load product page",
          severity: 'error',
        });
      }
    } else if (upgradeTo === Plan.Standard) {
      router.push({
        pathname: '/payments/products',
        query: { context: 'paid' },
      });
    }
  };
}

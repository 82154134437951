import { CSSProperties, FC } from 'react';
import { Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useRouter } from 'next/router';
import {
  adaptV4Theme,
  createTheme,
  StyledEngineProvider,
  Theme,
  ThemeProvider,
} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import BorderlessLinkButton, {
  BorderlessLinkButtonProps,
} from '../../BorderlessLinkButton';
import { PRIMARY_BLUE } from '../../../colors';

interface NavButtonProps {
  href: string;
  alternateHrefs?: string[];
  title: string;
  variant?: 'typography' | 'button';
  rootStyle?: CSSProperties;
  typographyStyle?: CSSProperties;
  padded?: boolean;
  borderlessLinkButtonProps?: BorderlessLinkButtonProps;
  animateHover?: boolean;
  defaultBold?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link1: {
      color: theme.palette.primary.main,
      position: 'relative',
      display: 'inline-block',
      '&:before': {
        content: "''",
        position: 'absolute',
        width: 0,
        height: 3,
        bottom: 0,
        left: 0,
        backgroundColor: 'white',
        visibility: 'hidden',
        transition: 'all 0.3s ease-in-out',
      },
      '&:hover:before': {
        visibility: 'visible',
        width: '100%',
      },
    },
    link2: {
      color: theme.palette.primary.main,
      position: 'relative',
      display: 'inline-block',
      '&:before': {
        content: "''",
        position: 'absolute',
        width: 0,
        height: 3,
        bottom: 0,
        left: 0,
        backgroundColor: 'black',
        visibility: 'hidden',
        transition: 'all 0.3s ease-in-out',
      },
      '&:hover:before': {
        visibility: 'visible',
        width: '100%',
      },
    },
  }),
);

export const NavButton: FC<NavButtonProps> = ({
  href,
  alternateHrefs,
  title,
  variant = 'typography',
  rootStyle,
  typographyStyle,
  padded,
  borderlessLinkButtonProps,
  animateHover = true,
  defaultBold = false,
}) => {
  const classes = useStyles();
  const router = useRouter();

  const isPathnameSameAsRef =
    router.pathname === href || alternateHrefs?.includes(router.pathname);

  return (
    <div
      style={{
        ...rootStyle,
        width: '100%',
      }}
    >
      <BorderlessLinkButton
        href={href}
        withPadding={padded}
        {...borderlessLinkButtonProps}
      >
        <StyledEngineProvider injectFirst>
          <ThemeProvider
            theme={createTheme(
              adaptV4Theme({
                typography: {
                  fontFamily: ['Nunito Sans'].join(','),
                },
              }),
            )}
          >
            {variant === 'button' ? (
              <Button
                variant={'contained'}
                style={{ textTransform: 'none', color: PRIMARY_BLUE }}
                disableElevation
              >
                {title}
              </Button>
            ) : (
              <Typography
                className={
                  // eslint-disable-next-line no-nested-ternary
                  animateHover
                    ? defaultBold
                      ? classes.link1
                      : classes.link2
                    : null
                }
                style={{
                  ...typographyStyle,
                  color: defaultBold ? 'white' : PRIMARY_BLUE,
                  fontWeight: isPathnameSameAsRef ? 'bold' : null,
                }}
                variant={'subtitle1'}
              >
                {title}
              </Typography>
            )}
          </ThemeProvider>
        </StyledEngineProvider>
      </BorderlessLinkButton>
    </div>
  );
};

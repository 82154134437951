import React, { FC, useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { PromptButton } from './PromptButton';

export type PromptContext =
  | 'web'
  | 'affiliations'
  | 'technologies'
  | 'publications';

export type WebContextPrompt =
  | 'Summarize'
  | 'Summarize.Risk'
  | 'Summarize.Risk-Allies'
  | 'Identify.Keywords'
  | 'Identify.Defence-Applications'
  | 'Extract.Entities'
  | 'List.Entities'
  | 'Analyze.Applications'
  | 'Analyze.Risk'
  | 'General'
  | 'Elliot';

export type PublicationsContextPrompt = WebContextPrompt;

export type TechContextPrompt =
  | 'Summarize'
  | 'Usage'
  | 'Identify.Defence-Applications.Risks'
  | 'List.Entities'
  | 'Analyze.Applications';

export type AffiliationsContextPrompt =
  | 'Summarize'
  | 'Identify.Leadership'
  | 'Identify.Collaboration'
  | 'Identify.Applications'
  | 'Highlight.Defence-Applications'
  | 'Identify.Environmental.Ethical.Risk'
  | 'Highlight.Breakthroughs'
  | 'Discuss.Policies'
  | 'Examine.Prospects';

export interface PromptButtonsProps {
  context: PromptContext;
  onPromptSelected: (prompt: string) => void;
}

export const getPrompts: (
  context: PromptContext,
) =>
  | WebContextPrompt[]
  | TechContextPrompt[]
  | AffiliationsContextPrompt[]
  | PublicationsContextPrompt[] = (context: PromptContext) => {
  switch (context) {
    case 'web':
      return [
        'Summarize',
        'Summarize.Risk',
        'Summarize.Risk-Allies',
        'Identify.Keywords',
        'Identify.Defence-Applications',
        'Extract.Entities',
        'List.Entities',
        'Analyze.Applications',
        'Analyze.Risk',
      ];
    case 'publications':
      return [
        'Summarize',
        'Summarize.Risk',
        'Summarize.Risk-Allies',
        'Identify.Keywords',
        'Identify.Defence-Applications',
        'Extract.Entities',
        'List.Entities',
        'Analyze.Applications',
        'Analyze.Risk',
      ];
    case 'affiliations':
      return [
        'Summarize',
        'Identify.Leadership',
        'Identify.Collaboration',
        'Identify.Applications',
        'Highlight.Defence-Applications',
        'Identify.Environmental.Ethical.Risk',
        'Highlight.Breakthroughs',
        'Discuss.Policies',
        'Examine.Prospects',
      ];
    case 'technologies':
      return [
        'Summarize',
        'Usage',
        'Identify.Defence-Applications.Risks',
        'List.Entities',
        'Analyze.Applications',
      ];
    default:
      return [];
  }
};

export const PromptButtons: FC<PromptButtonsProps> = ({
  context,
  onPromptSelected,
}) => {
  const prompts = getPrompts(context);

  const [action, setAction] = React.useState<
    | WebContextPrompt
    | TechContextPrompt
    | AffiliationsContextPrompt
    | PublicationsContextPrompt
  >('Summarize');

  const onSelection = (
    action:
      | WebContextPrompt
      | AffiliationsContextPrompt
      | TechContextPrompt
      | PublicationsContextPrompt,
  ) => {
    setAction(action);
    onPromptSelected(action);
  };

  useEffect(() => {
    setAction('Summarize');
  }, [context]);

  return (
    <Grid
      container
      direction={'row'}
      justifyContent={'center'}
      sx={{
        py: 1,
        overflowY: 'auto',
        height: 80,
        mb: 1,
      }}
    >
      {prompts.map((prompt: string) => (
        <Box key={`context-${prompt}`} sx={{ p: 0.5 }}>
          <PromptButton
            prompt={prompt}
            selected={action === prompt}
            onPromptSelected={onSelection}
          />
        </Box>
      ))}
    </Grid>
  );
};

import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  darken,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { alpha, styled } from '@mui/material/styles';
// eslint-disable-next-line import/no-cycle
// eslint-disable-next-line import/no-cycle
import { useRouter } from 'next/router';
import { Icon } from '@iconify/react';
// eslint-disable-next-line import/no-cycle
import { ChatBotContext } from '../../../../Context/ChatBotContext';
import {
  AffiliationsContextPrompt,
  PromptButtons,
  PromptContext,
  TechContextPrompt,
  WebContextPrompt,
  PublicationsContextPrompt,
} from '../Prompts';
// eslint-disable-next-line import/no-cycle
import { ChatBotSession, Role } from '../ChatBotSession';
import { SearchInput } from '../../../elements/SearchInput';
import { Plan } from '../../../../shared/types';
import { PlanContext } from '../../../../Context/PlanContext';

const HEIGHT = 600;
const WIDTH = 500;

const PurpleSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#820eff',
    '&:hover': {
      backgroundColor: alpha('#820eff', theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#dedede',
  },
}));

export type Session = {
  type:
    | WebContextPrompt
    | TechContextPrompt
    | AffiliationsContextPrompt
    | PublicationsContextPrompt;
  context: PromptContext;
  question: string;
  role: Role;
  title: string;
};

const TOKENS_ARRAY = ['1000', '2000', '3000'];

export const ChatBotOverlay = (props: { loggedIn: boolean }) => {
  const { mode, setMode } = useContext(ChatBotContext);
  const [fullscreen, setFullscreen] = useState<boolean>(false);
  const [sessions, setSessions] = useState<Session[]>([]);
  const [selectedPrompt, setSelectedPrompt] = useState<
    | WebContextPrompt
    | TechContextPrompt
    | AffiliationsContextPrompt
    | PublicationsContextPrompt
  >('Summarize');
  const [checked, setChecked] = useState(true);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);

  useEffect(() => {
    setIsAuthorized(true);
    setSessions([
      ...sessions,
      {
        type: selectedPrompt,
        question: mode.data,
        role: mode.role,
        context: mode.promptContext,
        title: mode.title,
      },
    ]);
  }, [mode.data]);

  useEffect(() => {
    setSelectedPrompt('Summarize');
  }, [mode.promptContext]);

  const [searchTerms, setSearchTerms] = useState<string[]>([]);

  const getHeight = (height: number) => (fullscreen ? height * 1.2 : height);

  const getWidth = (width: number) => (fullscreen ? width * 1.8 : width);
  const ref = useRef(null);

  const scrollToBottom = () => {
    ref?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  const router = useRouter();
  const plan = useContext(PlanContext);

  const loadStripePortal = () => {
    if (!props.loggedIn) {
      router.push('/auth/register');
    } else if (plan === Plan.Demo) {
      router.push('/payments/products');
    }
  };

  const [tokens, setTokens] = React.useState('1000');

  const handleTokensChange = (event: SelectChangeEvent) => {
    setTokens(event.target.value);
  };

  const [minimize, setMinimize] = useState<boolean>(false);

  useEffect(() => {
    setMinimize(false);
  }, [mode.data]);

  const BotIcon = () => (
    <Icon icon="lucide:bot" color={'#c19ce8'} fontSize={32} />
  );

  return (
    <Box
      sx={{
        position: 'fixed',
        zIndex: 3000,
        bottom: 12,
        right: 12,
      }}
    >
      {minimize && (
        <Paper
          sx={{
            backgroundColor: '#343540',
            width: 210,
            px: 2,
            py: 1,
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: darken('#343540', 0.2),
            },
          }}
          onClick={() => {
            setMinimize(false);
          }}
        >
          <Stack
            direction={'row'}
            spacing={1}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Typography sx={{ color: 'white' }}>
              {`${
                mode?.promptContext?.charAt(0).toUpperCase() +
                mode?.promptContext?.slice(1)
              } (${sessions?.length})`}
            </Typography>
            <BotIcon />
          </Stack>
        </Paper>
      )}
      <Paper
        sx={{
          height: minimize ? 0 : getHeight(HEIGHT),
          width: minimize ? 0 : getWidth(WIDTH),
          px: minimize ? 0 : 1,
          pb: minimize ? 0 : 1.5,
          pt: minimize ? 0 : 1,
          backgroundColor: '#343540',
        }}
      >
        <Stack
          sx={{ height: getHeight(HEIGHT) }}
          justifyContent={'space-between'}
        >
          <Stack>
            <Stack direction={'row'} sx={{ pl: 1 }}>
              <SearchInput
                name={'filter'}
                inputProps={{
                  sx: { color: 'white', width: '50%' },
                  startAdornment: !minimize && (
                    <InputAdornment position="start" sx={{ mb: 0.5 }}>
                      <BotIcon />
                    </InputAdornment>
                  ),
                }}
                queryCallback={(q) => {
                  setSearchTerms(q.split(' '));
                }}
              />
              {/*   <Box sx={{ px: 2 }}>
                <FormControlLabel
                  sx={{ color: 'white' }}
                  control={
                    <PurpleSwitch checked={checked} onChange={handleChange} />
                  }
                  label="Concise"
                />
              </Box> */}
              <Box sx={{ flexGrow: 1 }} />
              <Stack direction={'row'} alignItems={'center'}>
                <FormControl
                  size="small"
                  sx={{ height: minimize ? 0 : null, mr: 1.5 }}
                >
                  <Select
                    id="token-select-small"
                    value={tokens}
                    displayEmpty
                    input={<OutlinedInput />}
                    sx={{
                      color: 'white',
                      '.MuiSvgIcon-root ': {
                        fill: 'gray !important',
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(228, 219, 233, 0.25)',
                      },
                    }}
                    onChange={handleTokensChange}
                    MenuProps={{
                      sx: {
                        zIndex: 4000,
                        '& .MuiPaper-root': {
                          backgroundColor: darken('#343540', 0.1),
                          color: 'white',
                          '& .MuiMenuItem-root': {
                            '&:hover': {
                              backgroundColor: darken('#343540', 0.3),
                            },
                          },
                        },
                      },
                    }}
                  >
                    {TOKENS_ARRAY.map((token) => (
                      <MenuItem key={`Menu-Item-${token}`} value={token}>
                        {`${token} Tokens`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <IconButton
                  size={'small'}
                  sx={{
                    mr: 1,
                    color: 'white',
                    backgroundColor: darken('#343540', 0.2),
                    '&:hover': {
                      backgroundColor: darken('#343540', 0.4),
                    },
                  }}
                  onClick={() => {
                    setMinimize(!minimize);
                  }}
                >
                  {!minimize ? <Icon icon="fluent:minimize-12-filled" /> : null}
                </IconButton>
                <IconButton
                  size={'small'}
                  sx={{
                    color: 'white',
                    '&:hover': {
                      backgroundColor: darken('#343540', 0.2),
                    },
                  }}
                  onClick={() => {
                    setFullscreen(!fullscreen);
                  }}
                >
                  {fullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
                </IconButton>
                <IconButton
                  sx={{
                    color: 'white',
                    '&:hover': {
                      backgroundColor: darken('#343540', 0.2),
                    },
                  }}
                  size={'small'}
                  onClick={() => {
                    setMode({
                      context: 'Summarize',
                      active: false,
                      role: 'user',
                      title: '',
                      promptContext: 'web',
                      data: '',
                    });
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Stack>
            </Stack>
            <Stack
              spacing={1}
              sx={{
                height: getHeight(fullscreen ? 475 : 450),
                overflow: 'auto',
                m: 1,
                p: 3,
                backgroundColor: '#444653',
              }}
            >
              {isAuthorized ? (
                <>
                  {sessions.map((session, i) => (
                    <Box key={`${session.question + i + session.type}message`}>
                      <ChatBotSession
                        currentPrompt={selectedPrompt}
                        newSessionSelected={(session: Session) => {
                          setSessions([...sessions, session]);
                        }}
                        searchTerms={searchTerms}
                        isAuthorized={(auth) => {
                          setIsAuthorized(auth);
                        }}
                        sx={{ color: 'white' }}
                        session={session}
                        concise={checked}
                        fullscreen={fullscreen}
                        onUpdate={() => {
                          scrollToBottom();
                        }}
                        tokens={tokens}
                      />
                    </Box>
                  ))}
                  <Box ref={ref} />
                </>
              ) : (
                <Stack spacing={1}>
                  <Typography sx={{ color: 'white' }}>
                    You have to upgrade to premium to use this feature
                  </Typography>
                  <Button
                    startIcon={<Icon icon="fluent:premium-24-filled" />}
                    variant={'outlined'}
                    sx={{
                      backgroundColor: '#6409d3',
                      borderColor: 'white',
                      color: 'white',
                      '&:hover': {
                        backgroundColor: darken('#6409d3', 0.2),
                      },
                    }}
                    onClick={() => {
                      setMode({
                        context: 'Summarize',
                        active: false,
                        role: 'user',
                        title: '',
                        promptContext: 'web',
                        data: '',
                      });
                      loadStripePortal();
                    }}
                  >
                    Upgrade
                  </Button>
                </Stack>
              )}
            </Stack>
          </Stack>
          <PromptButtons
            context={mode.promptContext}
            onPromptSelected={(
              prompt:
                | WebContextPrompt
                | PublicationsContextPrompt
                | AffiliationsContextPrompt
                | TechContextPrompt,
            ) => {
              setSelectedPrompt(prompt);
            }}
          />
        </Stack>
      </Paper>
    </Box>
  );
};

// eslint-disable-next-line no-use-before-define
import React, { FC, useEffect, useRef, useState } from 'react';
import {
  ClickAwayListener,
  darken,
  Divider,
  MenuList,
  Popper,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import ArrowDownIcon from '@mui/icons-material/ArrowDropDown';
import { Paper } from '../Paper';
import { NavButton } from '../NavButton';

interface NavMenuProps {
  title: string;
  alternateHrefs: string[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuItem: {
      color: 'white',
      backgroundColor: theme.palette.background.default,
      '&:hover': {
        backgroundColor: darken(theme.palette.background.default, 0.05),
      },
    },
    paper: {
      backgroundColor: theme.palette.background.default,
    },
    popper: {
      zIndex: 3000,
    },
  }),
);

export const NavMenu: FC<NavMenuProps> = ({
  title,
  children,
  alternateHrefs,
}) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current?.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
      <div
        ref={anchorRef}
        onClick={handleToggle}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <NavButton
          href={''}
          alternateHrefs={alternateHrefs}
          title={title}
          animateHover={false}
        />
        <ArrowDownIcon style={{ color: 'black' }} />
      </div>
      <Popper
        className={classes.popper}
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-start"
        disablePortal
      >
        <Paper className={classes.paper}>
          <ClickAwayListener onClickAway={handleClose}>
            <MenuList
              autoFocusItem={open}
              id={`composition-menu-${title}`}
              aria-labelledby="composition-button"
              onKeyDown={handleListKeyDown}
              sx={{ minWidth: 120 }}
            >
              {React.Children.map(children, (child, i) => (
                <div
                  className={classes.menuItem}
                  key={`${title}Child${i}`}
                  onClick={handleClose}
                >
                  {child}
                  {i !== React.Children.count(children) - 1 && (
                    <Divider component="li" style={{ height: 0.8 }} />
                  )}
                </div>
              ))}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </div>
  );
};

import { FC, useEffect, useState } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import { Icon } from '@iconify/react';
import { Accuracy, Field } from '@models/CriteriaData';

export interface FieldsMenuProps {
  fields: Field[] | Accuracy[] | string[];
  onFieldSelected(field: string): void;
  defaultFieldPosition?: number;
  width?: number;
  withChange?: boolean;
}

export const mapFieldToLabel = (field: Field | Accuracy | string): string => {
  switch (field) {
    case 'query':
      return 'Query';
    case 'literal_name':
      return 'Organization';
    case 'organization':
      return 'Organization';
    case 'emails':
      return 'Email';
    case 'domains':
      return 'Domain';
    case 'funder_name':
      return 'Funder';
    case 'funder_award':
      return 'Award';
    case 'publications.title':
      return 'Title';
    case 'affiliations':
      return 'Affiliations';
    case 'affiliationsType':
      return 'Affiliations Type';
    case 'affiliationsCountry':
      return 'Affiliations Country Code';
    case 'keywords':
      return 'Keyword';
    case 'journal_name':
      return 'Journal';
    case 'publisher':
      return 'Publisher';
    case 'authors':
      return 'Author';
    case 'title':
      return 'Title';
    case 'source':
      return 'Source';
    case 'abstract':
      return 'Abstract';
    case 'funder.award':
      return 'Award';
    case 'funder.name':
      return 'Funder';
    case 'funders':
      return 'Funder';
    case 'author_.affiliation':
      return 'Affiliation';
    case 'author_.name':
      return 'Author';
    case 'author_.email':
      return 'Email';
    case 'citedCount':
      return 'Cited Count';
    case 'workCount':
      return 'Works Count';
    case 'institutionType':
      return 'Institution Type';
    case 'institutionContinent':
      return 'Institution Continent';
    case 'institutionName':
      return 'Institution';
    case 'hasOrcid':
      return 'Has Orcid';
    case 'content':
      return 'Content';
    case 'published_date':
      return 'Published Date';
    case 'data_id':
      return 'ID';
    case 'raw_affiliation':
      return 'Affiliation';
    default:
      return field;
  }
};

export const FieldsMenu: FC<FieldsMenuProps> = ({
  fields,
  onFieldSelected,
  defaultFieldPosition,
  width,
  withChange,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const [selectedField, setSelectedField] = useState<
    Field | Accuracy | string
  >();

  useEffect(() => {
    setSelectedField(fields[defaultFieldPosition || 0]);
    onFieldSelected(fields[defaultFieldPosition || 0]);
  }, []);

  const reset = () => {
    setAnchorEl(null);
  };

  const handleClose = (field: Field) => {
    setSelectedField(field);
    if (onFieldSelected) {
      onFieldSelected(field);
    }
    reset();
  };

  return (
    <>
      <Button
        variant={'outlined'}
        sx={{
          textTransform: 'none',
          borderRadius: 0,
          width: width || 120,
          height: 45,
          color: 'gray',
          '&:hover': {
            borderColor: '#631da8',
          },
        }}
        onClick={handleClick}
        endIcon={
          <Icon
            icon={open ? 'iconoir:nav-arrow-down' : 'iconoir:nav-arrow-up'}
            fontSize={25}
          />
        }
      >
        {mapFieldToLabel(selectedField)}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={reset}
        sx={{ zIndex: 2200 }}
      >
        {fields?.map((field) => (
          <MenuItem
            key={`${field}-field`}
            onClick={() => handleClose(field)}
            sx={{ width }}
          >
            {mapFieldToLabel(field)}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

import { FC, useEffect, useState } from 'react';
import { Button, IconButton, Paper, Stack } from '@mui/material';
import { Icon } from '@iconify/react';
import { Accuracy, CriteriaData, Field } from '@models/CriteriaData';
import { v4 as uuidv4 } from 'uuid';
import { CriteriaSelector } from './CriteriaSelector';
import { LogicalOperator } from './CriteriaSelector/OperatorMenu';
import { Error } from '../../../elements/Error';
import { SearchContext } from '../../types';
import { countryListAllIsoData } from '../../../../constants';

export interface AdvancedSearchProps {
  searchContext: SearchContext;
  onAdvancedSearchClicked: (criteria: CriteriaData[]) => void;
  elevation?: number;
}
export interface ExtendedCriteriaData {
  id: string;
  criteriaData: CriteriaData;
}

const DEFAULT_CRITERIA: CriteriaData = {
  q: '',
  field: 'keywords',
  accuracy: 'Vague',
  operator: 'AND',
};

export const getFields = (searchContext: SearchContext): Field[] => {
  switch (searchContext) {
    case 'organizations':
      return [
        'literal_name',
        'keywords',
        'emails',
        'domains',
        'publications.title',
        'affiliations',
      ];
    case 'technologies':
      return [
        'keywords',
        'journal_name',
        'publisher',
        'authors',
        'affiliations',
      ];
    case 'rare-technologies':
      return [
        'keywords',
        'journal_name',
        'publisher',
        'authors',
        'affiliations',
      ];
    case 'publications':
      return [
        'title',
        'abstract',
        'source',
        'domains',
        'funder.award',
        'funder.name',
        'journal_name',
        'authors',
        'keywords',
        'affiliations',
      ];
    case 'people':
      return [
        'query',
        'keywords',
        'domains',
        'funders',
        'organization',
        'affiliations',
        'authors',
      ];
    case 'open-alex-people':
      return [
        'query',
        'citedCount',
        'workCount',
        'institutionName',
        'institutionType',
        'institutionContinent',
        'affiliations',
        'affiliationsType',
        'affiliationsCountry',
        'hasOrcid',
      ];
    case 'awards':
      return [
        'query',
        'keywords',
        'funder_name',
        'funder_award',
        'domains',
        'affiliations',
      ];
    case 'web':
      return [
        'content',
        'title',
        'data_id',
        'published_date',
        'keywords',
        'source',
        'raw_affiliation',
      ];
    case 'images':
      return [
        'content',
        'title',
        'data_id',
        'published_date',
        'keywords',
        'source',
        'raw_affiliation',
      ];
    case 'author_':
      return ['author_.affiliation', 'author_.name', 'author_.email'];
    default:
      return [];
  }
};

export const AdvancedSearch: FC<AdvancedSearchProps> = ({
  searchContext,
  onAdvancedSearchClicked,
  elevation,
}) => {
  const fields = getFields(searchContext);

  const getDefaultExtendedCriteria = (): ExtendedCriteriaData[] => [
    {
      id: uuidv4(),
      criteriaData: { ...DEFAULT_CRITERIA, field: fields[0], operator: 'OR' },
    },
    { id: uuidv4(), criteriaData: { ...DEFAULT_CRITERIA, field: fields[1] } },
  ];

  const [criteriaData, setCriteriaData] = useState<ExtendedCriteriaData[]>(
    getDefaultExtendedCriteria(),
  );

  useEffect(() => {
    setCriteriaData(getDefaultExtendedCriteria());
  }, [searchContext]);

  const ICON_BUTTON_SX = {
    backgroundColor: '#f1f1f1',
    width: 40,
    height: 40,
    '&:hover': {
      backgroundColor: '#e5e5e5',
    },
  };

  const AddCriteria = () => (
    <IconButton
      sx={{ ...ICON_BUTTON_SX }}
      onClick={() => {
        setCriteriaData([
          ...criteriaData,
          {
            id: uuidv4(),
            criteriaData: {
              q: '',
              field: 'query',
              accuracy: 'Accurate',
              operator: 'AND',
            },
          },
        ]);
      }}
    >
      <Icon icon="akar-icons:plus" width={21} height={15} />
    </IconButton>
  );
  const RemoveCriteria = (props: { id: string }) => (
    <IconButton
      sx={{ ...ICON_BUTTON_SX }}
      onClick={() => {
        setCriteriaData(criteriaData.filter((c) => c.id !== props.id));
      }}
    >
      <Icon icon="akar-icons:minus" width={21} height={15} />
    </IconButton>
  );

  const getCriteriaButtons = (i: number, id: string) => {
    if (i === 0 && criteriaData.length === 1) {
      return <AddCriteria />;
    }

    if (i === criteriaData.length - 1) {
      return (
        <Stack direction={'row'} spacing={1}>
          <RemoveCriteria id={id} />
          <AddCriteria />
        </Stack>
      );
    }

    if (i < criteriaData.length - 1 && i !== 0) {
      return <RemoveCriteria id={id} />;
    }

    return null;
  };

  const [error, setError] = useState<string>();

  const onQueryChange = (q: string, criteriaData0: ExtendedCriteriaData) => {
    if (error && error === criteriaData0.id) {
      setError(null);
    }

    setCriteriaData(
      criteriaData.map((c) => {
        if (c.id === criteriaData0.id) {
          return {
            ...c,
            criteriaData: { ...c.criteriaData, q },
          };
        }

        return c;
      }),
    );
  };

  const onOperatorSelected = (
    logicalOperator: LogicalOperator,
    criteriaData0: ExtendedCriteriaData,
  ) => {
    setCriteriaData(
      criteriaData.map((c) => {
        if (c.id === criteriaData0.id) {
          return {
            ...c,
            criteriaData: { ...c.criteriaData, operator: logicalOperator },
          };
        }

        return c;
      }),
    );
  };

  const onAccuracySelected = (
    accuracy: Accuracy,
    criteriaData0: ExtendedCriteriaData,
  ) => {
    setCriteriaData(
      criteriaData.map((c) => {
        if (c.id === criteriaData0.id) {
          return {
            ...c,
            criteriaData: { ...c.criteriaData, accuracy },
          };
        }

        return c;
      }),
    );
  };

  const onFieldSelected = (
    field: Field,
    criteriaData0: ExtendedCriteriaData,
  ) => {
    setCriteriaData(
      criteriaData.map((c) => {
        if (c.id === criteriaData0.id) {
          return {
            ...c,
            criteriaData: { ...c.criteriaData, field },
          };
        }

        return c;
      }),
    );
  };

  const search = () => {
    const searchedCriteriaData = criteriaData.find(
      (c) => c.criteriaData.q === '',
    );

    if (!searchedCriteriaData) {
      onAdvancedSearchClicked(criteriaData.map((c) => c.criteriaData));
    } else {
      setError(searchedCriteriaData.id);
    }
  };

  function handleKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault();
      search();
    }
  }

  function getFilterType(field: Field): 'search' | 'number' | 'select' {
    switch (field) {
      case 'citedCount':
        return 'number';
      case 'workCount':
        return 'number';
      case 'institutionContinent':
        return 'select';
      case 'institutionType':
        return 'select';
      case 'affiliationsType':
        return 'select';
      case 'affiliationsCountry':
        return 'select';
      case 'hasOrcid':
        return 'select';
      default:
        return 'search';
    }
  }

  function getSelectList(field: Field): string[] {
    switch (field) {
      case 'institutionContinent':
        return [
          'Africa',
          'Antarctica',
          'Asia',
          'Europe',
          'North America',
          'Oceania',
          'South America',
        ];
      case 'institutionType':
        return [
          'Education',
          'Healthcare',
          'Company',
          'Archive',
          'Nonprofit',
          'Government',
          'Facility',
          'Other',
        ];
      case 'affiliationsCountry':
        return countryListAllIsoData?.map(
          (country) => `${country?.name} (${country?.code})`,
        );
      case 'affiliationsType':
        return [
          'Education',
          'Healthcare',
          'Company',
          'Archive',
          'Nonprofit',
          'Government',
          'Facility',
          'Other',
        ];
      case 'hasOrcid':
        return ['Orcid (Maybe)', 'Orcid (Has)'];
      case 'workCount':
        return ['>10', '>100', '>500', '>1000', '>10000'];
      case 'citedCount':
        return ['>10', '>100', '>500', '>1000', '>10000'];
      default:
        return [];
    }
  }

  const getOpenAlexDisabledOperators = (field: Field): LogicalOperator[] => {
    switch (field) {
      case 'query':
        return ['NOT'];
      case 'citedCount':
        return ['NOT'];
      case 'workCount':
        return ['NOT'];
      case 'hasOrcid':
        return ['NOT', 'OR'];
      default:
        return [];
    }
  };

  return (
    <Paper elevation={elevation || 2} square={true} sx={{ p: 2 }}>
      <Stack spacing={1}>
        {error && (
          <Error
            error={Boolean(error)}
            onCloseCallback={() => {
              setError(null);
            }}
          >
            {'Please fill in all the fields'}
          </Error>
        )}
        {criteriaData.map((criteriaData, index) => (
          <Stack
            key={`${criteriaData.id}-criteria-stack`}
            direction={'row'}
            spacing={1}
          >
            <CriteriaSelector
              key={`${criteriaData.id}-criteria`}
              handleKeyDown={handleKeyDown}
              autoFocus={index === 0}
              error={error && error === criteriaData.id}
              defaultOptionPosition={index === 0 ? 0 : 1}
              disabledOperators={
                searchContext === 'open-alex-people'
                  ? getOpenAlexDisabledOperators(
                      criteriaData.criteriaData.field,
                    )
                  : []
              }
              onQueryChange={(q) => {
                onQueryChange(q, criteriaData);
              }}
              filterType={getFilterType(criteriaData.criteriaData.field)}
              filterSelectTypeList={getSelectList(
                criteriaData.criteriaData.field,
              )}
              withAccuracySelector={searchContext !== 'open-alex-people'}
              onOperatorSelected={(logicalOperator: LogicalOperator) => {
                onOperatorSelected(logicalOperator, criteriaData);
              }}
              onAccuracySelected={(accuracy: Accuracy) => {
                onAccuracySelected(accuracy, criteriaData);
              }}
              onFieldSelected={(field: Field) => {
                onFieldSelected(field, criteriaData);
              }}
              onFieldOptionSelected={(option: string) => {
                onQueryChange(option, criteriaData);
              }}
              withLogicalOperator={index !== 0}
              options={{
                fields,
              }}
            />
            {getCriteriaButtons(index, criteriaData.id)}
          </Stack>
        ))}
      </Stack>
      <Stack
        direction={'row'}
        spacing={2}
        justifyContent={'center'}
        sx={{ mt: 4 }}
      >
        <Button
          sx={{ textTransform: 'none' }}
          onClick={() => {
            setCriteriaData(getDefaultExtendedCriteria());
          }}
        >
          Reset
        </Button>
        <Button variant={'contained'} onClick={() => search()}>
          Search
        </Button>
      </Stack>
    </Paper>
  );
};

import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Box, ClickAwayListener, Drawer, Grid, Toolbar } from '@mui/material';
import { useRouter } from 'next/router';
import ClearIcon from '@mui/icons-material/Clear';
import { isMobile } from 'react-device-detect';

type ContextType = {
  showDrawer: (
    id?: string,
    onChange?: (lastId: string, closed: boolean) => void,
    children?: any,
  ) => void;
  hideDrawer: () => void;
};

const initialState: ContextType = {
  showDrawer: () => {},
  hideDrawer: () => {},
};

const WIDTH = 400;

const GlobalDrawerContext = createContext(initialState);
export const useGlobalDrawerContext = () => useContext(GlobalDrawerContext);

interface GlobalDrawerProps {
  changeMargin?: {
    initMargin: number;
    onChangeMargin: (margin: number) => void;
  };
}

export const GlobalDrawer: FC<GlobalDrawerProps> = ({
  children,
  changeMargin,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [drawerChildren, setDrawerChildren] = useState<any>();
  const [id, setId] = useState<string>(null);
  const [onChangeArr, setOnChangeArr] = useState<
    ((lastId: string, closed: boolean) => void)[]
  >([]);

  const showDrawer = (
    id: string,
    onChange: (lastId: string, closed: boolean) => void,
    drawerChildren?: any,
  ) => {
    if (onChange) {
      setOnChangeArr([...onChangeArr, onChange]);
    }
    setId(id);
    setDrawerChildren(drawerChildren);
    setOpen(true);
    if (changeMargin) {
      changeMargin.onChangeMargin(WIDTH);
    }
  };

  useEffect(() => {
    onChangeArr.forEach((onChange) => {
      onChange(id, false);
    });
  }, [id, onChangeArr]);

  const hideDrawer = useCallback(() => {
    setOpen(false);
    if (changeMargin) {
      changeMargin.onChangeMargin(changeMargin.initMargin);
    }

    if (onChangeArr) {
      onChangeArr.forEach((onChange) => {
        onChange(id, true);
      });
    }
  }, [changeMargin, id, onChangeArr]);

  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = () => {
      hideDrawer();
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [hideDrawer, router]);

  return (
    <GlobalDrawerContext.Provider value={{ showDrawer, hideDrawer }}>
      <Drawer
        anchor={'right'}
        open={open}
        onClose={hideDrawer}
        style={{ width: isMobile ? '100vw' : WIDTH, zIndex: 3000 }}
      >
        <Toolbar />
        <Box sx={{ width: isMobile ? '100vw' : WIDTH }}>
          <Box sx={{ display: 'flex', justifyContent: 'end', mr: 2 }}>
            <ClearIcon
              sx={{
                zIndex: 1000,
                cursor: 'pointer',
                color: 'grey',
                '&:hover': {
                  color: 'black',
                },
              }}
              onClick={() => hideDrawer()}
            />
          </Box>
          <Grid container item justifyContent={'center'} alignItems={'center'}>
            {drawerChildren}
          </Grid>
        </Box>
      </Drawer>
      {children}
    </GlobalDrawerContext.Provider>
  );
};

import PropTypes from 'prop-types';
import 'typeface-alfa-slab-one';
import {
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from '@mui/material/styles';
import {
  Box,
  CssBaseline,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import type { AppProps } from 'next/app';
import { useEffect, useMemo, useState } from 'react';
import { PlanContext } from 'Context/PlanContext';
import { User } from 'firebase/auth';
import { useRouter } from 'next/router';
import theme from '../theme';
import Layout from '../components/Layout';
import '../../styles/tree.css';
import { listenToUserState } from '../auth/firebase';
import { Plan } from '../shared/types';
import { Button } from '../components/elements/Button';
import { MoreOrLessText } from '../components/elements/MoreOrLessText';
import { ChatBotMode, ChatBotContext } from '../Context/ChatBotContext';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export default function MyApp(props) {
  const { Component, pageProps }: AppProps = props;

  const [plan, setPlan] = useState<Plan>(Plan.None);
  const [mode, setMode] = useState<ChatBotMode>({
    active: false,
    context: 'Summarize',
    promptContext: 'web',
    role: 'user',
    title: '',
    data: '',
  });

  // Context values passed to consumer
  const value = {
    mode,
    setMode,
  };

  const router = useRouter();

  useEffect(() => {
    function getPromptContext() {
      switch (router.pathname) {
        case '/search/organizations':
          return 'affiliations';
        case '/affiliation/[id]':
          return 'affiliations';
        case '/search/technologies':
          return 'technologies';
        case '/search/rare-technologies':
          return 'technologies';
        case '/technology/[keyword]':
          return 'technologies';
        case '/search/web':
          return 'web';
        case '/web/[data_id]':
          return 'web';
        default:
          return 'web';
      }
    }

    setMode({ ...mode, promptContext: getPromptContext() });
  }, [router.pathname]);

  useEffect(() => {
    listenToUserState(async (user: User) => {
      if (user) {
        user?.getIdTokenResult(true).then((idTokenResult) => {
          if (
            idTokenResult.claims.admin ||
            idTokenResult.claims.stripeRole === Plan.Premium.toString()
          ) {
            setPlan(Plan.Premium);
          } else if (
            idTokenResult.claims.stripeRole === Plan.Standard.toString()
          ) {
            setPlan(Plan.Standard);
          } else {
            setPlan(Plan.Demo);
          }
        });
      } else {
        setPlan(Plan.Demo);
      }
    });
  }, []);

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  // https://developer.mozilla.org/en-US/docs/Web/CSS/@media/prefers-color-scheme
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const selectedTheme = useMemo(() => theme(false), []);

  function ErrorFallback({ error, resetErrorBoundary }) {
    return (
      <Stack alignItems={'center'} sx={{ py: '15%' }} spacing={1}>
        <Typography variant={'h2'} sx={{ fontSize: 25 }}>
          Something went wrong:
        </Typography>
        <Box
          sx={{
            borderRadius: 1,
            borderColor: '#e1e1e1',
            borderStyle: 'solid',
            p: 2,
            maxWidth: '50%',
          }}
        >
          <MoreOrLessText text={error?.message} />
        </Box>
        <Button onClick={resetErrorBoundary}>Reload</Button>
      </Stack>
    );
  }

  return (
    <>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={selectedTheme}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          <ChatBotContext.Provider value={value}>
            <PlanContext.Provider value={plan}>
              <Layout>
                <Component />
              </Layout>
            </PlanContext.Provider>
          </ChatBotContext.Provider>
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
}

MyApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.object.isRequired,
};

import { AxiosInstance, AxiosResponse } from 'axios';
import { ParsedUrlQuery } from 'querystring';
import { IAffiliationSummaryStats } from '@models/IAffiliationSummary';
import { CriteriaData } from '@models/CriteriaData';
import AxiosClientBuilder, { ApiName } from '../../AxiosClientBuilder';

export class SearchApi {
  private static ROOT = '/search';

  private static searchClient: AxiosInstance = new AxiosClientBuilder()
    .setApiName(ApiName.Organizations)
    .setPrivate(true)
    .build();

  /**
   *
   * @param q the q by which the search matches affiliation names
   */
  static autocomplete(q: string): Promise<AxiosResponse<string[]>> {
    return this.searchClient.get<string[]>(`${this.ROOT}/autocomplete/`, {
      params: {
        q,
      },
    });
  }

  /**
   *
   * @param q the q by which the search matches affiliation names
   */
  static authorsAutocomplete(q: string): Promise<AxiosResponse<string[]>> {
    return this.searchClient.get<string[]>(
      `${this.ROOT}/authorsAutocomplete/`,
      {
        params: {
          q,
        },
      },
    );
  }

  static generalSearch(
    criteria: CriteriaData[],
    offset: number,
    {
      mostRecentPublications,
      totalPublications,
      email,
      domain,
      risk,
      impact,
    }: ParsedUrlQuery,
    trackTotalHits?: boolean,
  ): Promise<AxiosResponse<IAffiliationSummaryStats>> {
    return this.searchClient.get<IAffiliationSummaryStats>(`${this.ROOT}/`, {
      params: {
        criteria: JSON.stringify(criteria),
        offset,
        mostRecentPublications,
        totalPublications,
        email,
        domain,
        trackTotalHits,
        risk,
        impact,
      },
    });
  }

  static getRandomLiteralName(
    // optional filter
    literalName?: string,
  ): Promise<AxiosResponse<string>> {
    return this.searchClient.get<string>(`${this.ROOT}/random/`, {
      params: {
        literalName,
      },
    });
  }
}

import { FC } from 'react';
import {
  IconButton as MuiIconButton,
  IconButtonProps as MuiIconButtonProps,
} from '@mui/material';

interface IconButtonProps extends MuiIconButtonProps {
  name?: string;
}

export const IconButton: FC<IconButtonProps> = ({ name, ...rest }) => (
  <MuiIconButton data-testid={`iconButton:${name}`} {...rest} />
);

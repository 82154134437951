import React from 'react';
// eslint-disable-next-line import/no-cycle
import { Role } from '../components/modules/ChatBot/ChatBotSession';
import {
  AffiliationsContextPrompt,
  PromptContext,
  PublicationsContextPrompt,
  TechContextPrompt,
  WebContextPrompt,
} from '../components/modules/ChatBot/Prompts';

export type ChatBotMode = {
  active: boolean;
  context:
    | WebContextPrompt
    | TechContextPrompt
    | AffiliationsContextPrompt
    | PublicationsContextPrompt;
  promptContext: PromptContext;
  title: string;
  role: Role;
  data: string;
};

export const ChatBotContext = React.createContext<{
  mode: ChatBotMode;
  setMode: (chatBotMode: ChatBotMode) => void;
}>({
  mode: {
    active: false,
    context: 'Summarize',
    promptContext: 'web',
    role: 'user',
    title: '',
    data: '',
  },
  setMode: () => {},
});

import IRisk from '@models/IRisk';
import Fuse from 'fuse.js';
import { darken } from '@mui/material';
import { RiskType } from './types';
import { RiskLevel } from '../../../shared/types';
import { GREY } from '../../../colors';

export const getRiskColors = (lighterVeryHigh?: boolean) => {
  const otherColors = ['#FF0000', '#FFA500', '#008000'];

  if (lighterVeryHigh) {
    return [darken('#8154ea', 0.4), '#8154ea', ...otherColors];
  }

  return [darken('#6c1aff', 0.4), '#6c1aff', ...otherColors];
};

export const determineRiskType = (
  risk: IRisk[],
  lighterVeryHigh?: boolean,
): RiskType => {
  const colors = getRiskColors(lighterVeryHigh);

  if (risk) {
    const fuse = new Fuse(risk, {
      keys: ['level'],
    });

    if (fuse.search(`^Extreme`).length > 0) {
      return {
        level: RiskLevel.Extreme,
        color: colors[0],
      };
    }

    if (fuse.search('^Very | High').length > 0) {
      return {
        level: RiskLevel.VeryHigh,
        color: colors[1],
      };
    }

    if (fuse.search(`^High`).length > 0) {
      return { level: RiskLevel.High, color: colors[2] };
    }

    if (fuse.search(`^Medium`).length > 0) {
      return { level: RiskLevel.Medium, color: colors[3] };
    }

    if (fuse.search(`^Low`).length > 0) {
      return { level: RiskLevel.Low, color: colors[4] };
    }
  }

  return { level: RiskLevel.None, color: GREY };
};

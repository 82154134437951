const storageKey = 'cookie-meta';

export type CookieMeta = {
  expiresAt: number;
};

export const getCookieMeta = async (): Promise<CookieMeta> => {
  const cookieMeta = localStorage.getItem(storageKey);
  if (!cookieMeta) {
    return;
  }

  return JSON.parse(cookieMeta);
};

export const setCookieMeta = async (cookieMeta: CookieMeta) => {
  const currentCookieMeta = await getCookieMeta();
  const newCookieMeta = JSON.stringify({
    ...currentCookieMeta,
    ...cookieMeta,
  });

  localStorage.setItem(storageKey, newCookieMeta);
};

export const clearCookieMeta = () => {
  localStorage.removeItem(storageKey);
};

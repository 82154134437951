import { createTheme, adaptV4Theme } from '@mui/material/styles';

export type Theme = 'light' | 'dark';

const theme = (prefersDarkMode: boolean) => {
  let type;

  if (prefersDarkMode !== null) {
    type = prefersDarkMode ? 'dark' : 'light';
  }

  return createTheme(
    adaptV4Theme({
      palette: {
        mode: type,
        primary: {
          main: '#1d172b',
        },
        secondary: {
          main: '#44305a',
        },
        error: {
          main: '#B00020',
        },
        background: {
          default: '#ffffff',
        },
        text: {
          secondary: '#9572ce',
        },
      },
    }),
  );
};

export default theme;

import { UrlObject } from 'url';

export type TransitionOptions = {
  shallow?: boolean;
  locale?: string | false;
  scroll?: boolean;
};

export type Url = UrlObject | string;
export type Context = 'demo' | 'free_trial' | 'paid';

export enum Plan {
  None = 'none',
  Demo = 'demo',
  Premium = 'premium',
  Standard = 'standard',
}

export enum RiskLevel {
  Extreme = 'Extreme',
  VeryHigh = 'Very High',
  High = 'High',
  Medium = 'Medium',
  Low = 'Low',
  None = 'None',
}

export enum ImpactLevel {
  USUniversity = 'U.S. University',
  USEntity = 'U.S. Entity',
  GBUniversity = 'GB University',
  AUUniversity = 'AU University',
  JPUniversity = 'JP University',
  CAUniversity = 'CA University',
  NZUniversity = 'NZ University',
  SGUniversity = 'SG University',
  TWUniversity = 'TW University',
  NLUniversity = 'NL University',
  ILUniversity = 'IL University',
  None = 'None',
}

export type Theme = 'light' | 'dark';
export type NodeSelection = 10 | 25 | 50 | 75 | 100 | 500;

import {
  CircularProgress,
  CircularProgressProps as MuiCircularProgressProps,
} from '@mui/material';
import { FC } from 'react';

export interface LoadingProps extends MuiCircularProgressProps {
  name?: string;
}

export const Loading: FC<LoadingProps> = ({ name = '', ...rest }) => (
  <CircularProgress {...rest} data-testid={`loading:${name}`} />
);

import {
  InputBase as MuiInputBase,
  InputBaseProps as MuiInputBaseProps,
} from '@mui/material';
import { FC } from 'react';

export interface InputBaseProps extends MuiInputBaseProps {
  name?: string;
}

export const InputBase: FC<InputBaseProps> = ({
  name = '',
  inputProps,
  ...rest
}) => (
  <MuiInputBase
    {...rest}
    inputProps={{ 'data-testid': `inputBase:${name}`, ...inputProps }}
  />
);

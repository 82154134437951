import { Paper as MuiPaper, PaperProps as MuiPaperProps } from '@mui/material';
import { FC } from 'react';

export interface PaperProps extends MuiPaperProps {
  name?: string;
}

export const Paper: FC<PaperProps> = ({ name = '', ...rest }) => (
  <MuiPaper data-testid={`paper:${name}`} {...rest} />
);

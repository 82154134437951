import { Slide, Snackbar, Stack, Typography } from '@mui/material';
import { FC, useContext } from 'react';
import { useRouter } from 'next/router';
import { Plan } from '../../../shared/types';
import { PlanContext } from '../../../Context/PlanContext';

interface UpgradeSnackbarProps {
  excludedPaths: string[];
  loggedIn: boolean;
}

export const UpgradeSnackbar: FC<UpgradeSnackbarProps> = ({
  excludedPaths,
  loggedIn,
}) => {
  const plan = useContext(PlanContext);
  const router = useRouter();

  function TransitionRight(props) {
    return (
      <Slide {...props} direction="right">
        {props.children}
      </Slide>
    );
  }

  return (
    plan === Plan.Demo && (
      <Snackbar
        sx={{ cursor: 'pointer' }}
        open={plan === Plan.Demo && !excludedPaths.includes(router.pathname)}
        onClick={() => {
          if (loggedIn) {
            router.push('/payments/products');
          } else {
            router.push('/auth/register');
          }
        }}
        TransitionComponent={TransitionRight}
        ContentProps={{ sx: { backgroundColor: '#5a07be' } }}
        message={
          <Stack>
            <Typography variant={'subtitle2'}>
              {'Demo mode only allows access to 1% of our data.'}
            </Typography>
            <Typography>{'Upgrade from Demo. Register!'}</Typography>
          </Stack>
        }
      />
    )
  );
};

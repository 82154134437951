import { createContext, FC, useContext, useState } from 'react';
import { Alert, Snackbar } from '@mui/material';

export type Color = 'success' | 'info' | 'warning' | 'error';

interface PartialSnackbarState {
  message: string;
  severity: Color;
}

interface SnackbarState extends PartialSnackbarState {
  open: boolean;
}

type ContextType = {
  showSnackbar: (partialSnackbarState: PartialSnackbarState) => void;
  hideSnackbar: () => void;
};

const initialState: ContextType = {
  showSnackbar: () => {},
  hideSnackbar: () => {},
};

const GlobalSnackbarContext = createContext(initialState);
export const useGlobalSnackbarContext = () => useContext(GlobalSnackbarContext);

const initialSnackbarState: SnackbarState = {
  open: false,
  message: 'An error has occurred',
  severity: 'error',
};

export const GlobalSnackbar: FC = ({ children }) => {
  const [state, setState] = useState<{
    open: boolean;
    message: string;
    severity: Color;
  }>(initialSnackbarState);

  const handleClose = () => {
    setState({ ...initialSnackbarState });
  };

  const showSnackbar = (partialSnackbarState: PartialSnackbarState) => {
    setState({ ...partialSnackbarState, open: true });
  };

  const hideSnackbar = () => {
    setState({ ...initialSnackbarState });
  };

  return (
    <GlobalSnackbarContext.Provider value={{ showSnackbar, hideSnackbar }}>
      <Snackbar open={state.open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={state.severity}>
          {state.message}
        </Alert>
      </Snackbar>
      {children}
    </GlobalSnackbarContext.Provider>
  );
};

import { FC } from 'react';
import {
  ButtonBase as MuiButtonBase,
  ButtonBaseProps as MuiButtonBaseProps,
} from '@mui/material';

interface ButtonBaseProps extends MuiButtonBaseProps {
  name?: string;
}

export const ButtonBase: FC<ButtonBaseProps> = ({ name, ...rest }) => (
  <MuiButtonBase data-testid={`buttonBase:${name}`} {...rest} />
);

import React, { FC, useEffect } from 'react';
import Fuse from 'fuse.js';
import { Box } from '@mui/material';
import ReactMarkdown from 'react-markdown';

export interface HighlighterProps {
  search: string[];
  text: string;
}

export const Highlighter: FC<HighlighterProps> = ({ search, text }) => {
  const [highlightedText, setHighlightedText] = React.useState<string>('');

  useEffect(() => {
    const splittedText = text.split(' ').map((word) => word.trim());
    const fuse = new Fuse(splittedText, {
      findAllMatches: true,
      useExtendedSearch: true,
    });

    const result = fuse.search(search.map((s) => `^"${s}"`).join('|'));

    const highlights = splittedText
      .map((word: string, i) => {
        if (result.find((fuseResult) => fuseResult.refIndex === i)) {
          return `**${word}**`;
        }

        return word;
      })
      .join(' ');

    setHighlightedText(highlights);
  }, [text, search]);

  return (
    <Box sx={{ color: 'white' }}>
      <ReactMarkdown
        components={{
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          strong: ({ node, ...props }) => (
            <i
              style={{
                color: 'black',
                backgroundColor: 'yellow',
                paddingLeft: 2,
                paddingRight: 4,
              }}
              {...props}
            />
          ),
        }}
      >
        {highlightedText}
      </ReactMarkdown>
    </Box>
  );
};

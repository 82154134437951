import { FC } from 'react';
import {
  capitalizeFirstLetter,
  getTextWithHighlights,
} from '../../../shared/functions';

interface HighlightedTextProps {
  containedText: string | string[];
  text: string;
  color?: string;
  textColor?: string;
  textSize?: string | number;
}

export const HighlightedText: FC<HighlightedTextProps> = ({
  containedText,
  text,
  color,
  textColor,
  textSize,
}) => {
  if (containedText instanceof Array) {
    let textWithHighlights = `${text}`;

    containedText.forEach((ct) => {
      textWithHighlights = getTextWithHighlights(textWithHighlights, ct, color);
    });

    return <span dangerouslySetInnerHTML={{ __html: textWithHighlights }} />;
  }

  return (
    <span
      style={{ fontSize: textSize, color: textColor }}
      dangerouslySetInnerHTML={{
        __html: getTextWithHighlights(
          capitalizeFirstLetter(text as string),
          containedText,
          color,
        ),
      }}
    />
  );
};

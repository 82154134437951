import { FC } from 'react';
import { Collapse, Alert } from '@mui/material';
import { AlertProps } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '../IconButton';

interface ErrorProps {
  error: boolean;
  onCloseCallback?: () => void;
  alertProps?: AlertProps;
}

export const Error: FC<ErrorProps> = ({
  children,
  error,
  onCloseCallback,
  alertProps,
}) => (
  <Collapse in={error}>
    <Alert
      severity="error"
      action={
        <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          onClick={() => {
            if (onCloseCallback) {
              onCloseCallback();
            }
          }}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      }
      {...alertProps}
    >
      {children}
    </Alert>
  </Collapse>
);

import axios, { AxiosResponse } from 'axios';
import IOpenAlexAuthor from '@models/IOpenAlexAuthor';
import { CriteriaData, Field, Operator } from '@models/CriteriaData';
import { OpenAlexInstitutionApi } from '../institution';
import { countryListAllIsoData } from '../../../constants';

export class OpenAlexAuthorsApi {
  private static ROOT = 'https://api.openalex.org';

  static autocomplete(
    q: string,
  ): Promise<AxiosResponse<{ results: IOpenAlexAuthor[] }>> {
    return axios.get<{ results: IOpenAlexAuthor[] }>(
      `${this.ROOT}/autocomplete/authors`,
      {
        params: {
          q,
        },
      },
    );
  }

  static async getAuthors(
    criteria: CriteriaData[],
    page: number,
  ): Promise<
    AxiosResponse<{
      meta: { count: number; page: number; per_page: number };
      results: IOpenAlexAuthor[];
    }>
  > {
    const getContinentCode = (continent: string) => {
      switch (continent) {
        case 'Africa':
          return 'africa';
        case 'Asia':
          return 'asia';
        case 'Europe':
          return 'europe';
        case 'North America':
          return 'north_america';
        case 'Oceania':
          return 'oceania';
        case 'South America':
          return 'south_america';
        case 'Antarctica':
          return 'antarctica';
        default:
          return '';
      }
    };

    const chooseOperator = (operator: Operator, index: number) => {
      if (index === 0 && operator !== 'OR') {
        return '';
      }

      switch (operator) {
        case 'AND':
          return '';
        case 'OR':
          return '|';
        case 'NOT':
          return '!';
        default:
          return '';
      }
    };

    const processFilters = (
      criteria: CriteriaData[],
      field: Field,
      valueProcessor: (str: string) => string,
      selector: 'AND NOT' | 'OR',
    ) => {
      function fieldToOpenAlexField(): string {
        switch (field) {
          case 'query':
            return 'display_name.search';
          case 'institutionName':
            return 'last_known_institutions.id';
          case 'institutionContinent':
            return 'last_known_institutions.continent';
          case 'affiliations':
            return 'affiliations.institution.id';
          case 'workCount':
            return 'works_count';
          case 'citedCount':
            return 'cited_by_count';
          case 'hasOrcid':
            return 'has_orcid';
          case 'institutionType':
            return 'last_known_institutions.type';
          case 'affiliationsType':
            return 'affiliations.institution.type';
          case 'affiliationsCountry':
            return 'affiliations.institution.country_code';
          default:
            return '';
        }
      }

      if (selector === 'AND NOT') {
        return `${criteria
          .filter(
            (c) =>
              (c.operator === 'AND' || c.operator === 'NOT' || !c.operator) &&
              c.field === field,
          )
          .map(
            (value, index) =>
              `${fieldToOpenAlexField()}:${chooseOperator(
                value.operator,
                index,
              )}${valueProcessor(value.q)}`,
          )}`;
      }

      const filteredCriteria = criteria.filter(
        (c) => c.operator === 'OR' && c.field === field,
      );

      if (filteredCriteria?.length > 1) {
        return `${fieldToOpenAlexField()}:${filteredCriteria
          .map(
            (value, index) =>
              `${valueProcessor(value.q)}${
                index === filteredCriteria.length - 1
                  ? ''
                  : chooseOperator(value.operator, index)
              }`,
          )
          .join('')}`;
      }

      return filteredCriteria.map(
        (value, index) =>
          `${fieldToOpenAlexField()}:${chooseOperator(
            null,
            index,
          )}${valueProcessor(value.q)}`,
      );
    };

    const filter = [
      ...['AND NOT', 'OR'].map((selector) =>
        processFilters(
          criteria,
          'query',
          (str) => str,
          selector as 'AND NOT' | 'OR',
        ),
      ),
      ...['AND NOT', 'OR'].map((selector) =>
        processFilters(
          criteria,
          'institutionContinent',
          getContinentCode,
          selector as 'AND NOT' | 'OR',
        ),
      ),
      ...['AND NOT', 'OR'].map((selector) =>
        processFilters(
          criteria,
          'institutionType',
          (str) => str?.toLowerCase(),
          selector as 'AND NOT' | 'OR',
        ),
      ),
      ...['AND NOT', 'OR'].map((selector) =>
        processFilters(
          criteria,
          'affiliationsCountry',
          (str) =>
            countryListAllIsoData.find(
              (c) => c.code === str.match(/\(([^)]+)\)/)[1],
            )?.code,
          selector as 'AND NOT' | 'OR',
        ),
      ),
      ...['AND NOT', 'OR'].map((selector) =>
        processFilters(
          criteria,
          'affiliationsType',
          (str) => str?.toLowerCase(),
          selector as 'AND NOT' | 'OR',
        ),
      ),
      [
        processFilters(
          criteria,
          'hasOrcid',
          (str) => (str === 'Orcid (Maybe)' ? 'false' : 'true'),
          'AND NOT',
        ),
      ],
      ...['AND NOT', 'OR'].map((selector) =>
        processFilters(
          criteria,
          'workCount',
          (str) => str?.toLowerCase(),
          selector as 'AND NOT' | 'OR',
        ),
      ),
      ...['AND NOT', 'OR'].map((selector) =>
        processFilters(
          criteria,
          'citedCount',
          (str) => str?.toLowerCase(),
          selector as 'AND NOT' | 'OR',
        ),
      ),
    ];

    if (criteria?.find((c) => c.field === 'institutionName')) {
      const institutionsToProcess = [];

      for (const c1 of criteria?.filter((c) => c.field === 'institutionName')) {
        const res = await OpenAlexInstitutionApi.fetchInstitutions(c1?.q);

        if (res?.data?.results?.length > 0) {
          institutionsToProcess.push({ ...c1, q: res?.data?.results[0].id });
        }
      }

      filter?.push(
        processFilters(
          institutionsToProcess,
          'institutionName',
          (str) => str,
          'AND NOT',
        ),
      );

      filter?.push(
        processFilters(
          institutionsToProcess,
          'institutionName',
          (str) => str,
          'OR',
        ),
      );
    }

    if (criteria?.find((c) => c.field === 'affiliations')) {
      const affiliationsToProcess = [];

      for (const c1 of criteria?.filter((c) => c.field === 'affiliations')) {
        const res = await OpenAlexInstitutionApi.fetchInstitutions(c1?.q);

        if (res?.data?.results?.length > 0) {
          affiliationsToProcess.push({ ...c1, q: res?.data?.results[0].id });
        }
      }

      filter?.push(
        processFilters(
          affiliationsToProcess,
          'affiliations',
          (str) => str,
          'AND NOT',
        ),
      );

      filter?.push(
        processFilters(
          affiliationsToProcess,
          'affiliations',
          (str) => str,
          'OR',
        ),
      );
    }

    const actFilter = filter
      .flatMap((c) => c)
      .filter((a) => a?.length > 0 && a[0] !== '')
      .join(',')
      .replace(/,\s*$/, '');

    return axios.get<{
      meta: { count: number; page: number; per_page: number };
      results: IOpenAlexAuthor[];
    }>(`${this.ROOT}/authors`, {
      params: {
        page,
        'per-page': 10,
        filter:
          !actFilter || actFilter?.length === 0 || actFilter === ','
            ? null
            : actFilter,
      },
    });
  }

  static getAuthor(id: string): Promise<AxiosResponse<IOpenAlexAuthor>> {
    return axios.get<IOpenAlexAuthor>(`${this.ROOT}/authors/${id}`);
  }
}

import { FC } from 'react';
import { Button, darken } from '@mui/material';

export interface PromptButtonProps {
  prompt: string;
  onPromptSelected: (prompt: string) => void;
  selected: boolean;
}

export const PromptButton: FC<PromptButtonProps> = ({
  prompt,
  onPromptSelected,
  selected,
}) => (
  <Button
    sx={{
      py: 0,
      px: 1,
      color: 'white',
      textTransform: 'none',
      borderColor: !selected ? '#606060' : '#6409d3',
      backgroundColor: !selected ? null : '#6409d3',
      '&:hover': {
        borderColor: '#6409d3',
        backgroundColor: darken('#6409d3', 0.2),
      },
    }}
    size={'small'}
    variant={'outlined'}
    onClick={() => {
      onPromptSelected(prompt);
    }}
  >
    {prompt}
  </Button>
);

import { AxiosInstance, AxiosResponse } from 'axios';
import { ParsedUrlQuery } from 'querystring';
import IKeywordRisk from '@models/ITechSearchResults';
import IRareTechSearchResults from '@models/IRareTechSearchResults';
import { CriteriaData } from '@models/CriteriaData';
import AxiosClientBuilder, { ApiName } from '../../AxiosClientBuilder';

export class SearchApi {
  private static ROOT = '/search';

  private static searchClient: AxiosInstance = new AxiosClientBuilder()
    .setApiName(ApiName.Technologies)
    .setPrivate(true)
    .build();

  /**
   *
   * @param q the q by which the search matches affiliation names
   * @param affiliationsIndex which affiliations index to use
   */
  static autocomplete(
    q: string,
    affiliationsIndex?: boolean,
  ): Promise<AxiosResponse<string[]>> {
    return this.searchClient.get<string[]>(`${this.ROOT}/autocomplete/`, {
      params: {
        q,
        affiliationsIndex,
      },
    });
  }

  static generalSearch(
    criteria: CriteriaData[],
    { year, risk, impact }: ParsedUrlQuery,
    size?: number,
  ): Promise<AxiosResponse<IKeywordRisk[]>> {
    return this.searchClient.get<IKeywordRisk[]>(`${this.ROOT}/`, {
      params: {
        criteria: JSON.stringify(criteria),
        year,
        size,
        risk,
        impact,
      },
    });
  }

  static rareTechSearch(
    criteria: CriteriaData[],
    { year, risk, impact }: ParsedUrlQuery,
    size?: number,
  ): Promise<AxiosResponse<IRareTechSearchResults[]>> {
    return this.searchClient.get<IRareTechSearchResults[]>(
      `${this.ROOT}/rare`,
      {
        params: {
          criteria: JSON.stringify(criteria),
          year,
          size,
          risk,
          impact,
        },
      },
    );
  }
}

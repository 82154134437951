import { darken, lighten } from '@mui/material';
import { Theme } from './shared/types';

export const PRIMARY_BLUE = '#000052';
export const DARK_GREY = '#242424';
export const DISCO_ORANGE = '#EEC7BB';
export const DARK_PURPLE = '#271738';
export const DEEP_PURPLE = '#311b92';
export const LIGHT_PURPLE = '#ae7be1';
export const PURPLE = '#7731bb';
export const BLUE = '#1d55a8';
export const GREY = '#808080';
export const LIGHT_GREY = '#e7e7e7';

export const COUNT_DENDROGRAM = (theme: Theme = 'light') => ({
  STRATEGIC_COMPETITION: {
    SEARCH: {
      color: theme === 'dark' ? '#7a91b5' : '#7a91b5',
    },
    EXPAND_TO: {
      color: '#66407a',
    },
    DEPTH_ONE_TITLE: {
      color: 'black',
    },
    DEPTH_REST_TITLE: {
      color: 'black',
    },
    borderColor: 'background.default',
    background: 'background.default',
  },
  NODE: {
    background: LIGHT_GREY,
    circle: {
      main: '#4d4d4d',
      search_no_descendants1: '#797979',
      search_no_descendants2: '#6c6c6c',
      marked: '#ff0000',
    },
    line: {
      main: '#4d4d4d',
    },
    borderColor: 'black',
    backgroundColor: '#e7e7e7',
  },
  PALETTE: {
    iconColor: darken(LIGHT_GREY, 0.5),
  },
  EXPANSION_TOGGLE: {
    backgroundColor1: LIGHT_GREY,
    backgroundColor2: lighten(LIGHT_GREY, 0.9),
    color1: '#631da8',
    color2: lighten('#000000', 0.5),
    markColor: '#000000',
  },
  NODE_PARTITION: {
    buttonBackgroundColor: 'black',
    textColor: 'grey',
    iconColor: 'grey',
  },
  SEARCH_BAR: {
    PAPER: {
      backgroundColor: lighten(LIGHT_GREY, 0.9),
    },
    INPUT: {
      color: theme === 'light' ? 'black' : 'black',
      icon: theme === 'light' ? '#2f2f2f' : '#7d7d7d',
      placeholder_color: theme === 'light' ? 'black' : 'black',
    },
  },
});

export const RELATIONS_GRAPH = (theme: Theme = 'light') => ({
  Graph: {
    TITLE: {
      color: theme === 'dark' ? 'white' : 'black',
    },
    DESCRIPTION: {
      color: theme === 'dark' ? '#d6d6d6' : null,
    },
  },
  LINK: { color: '#d2d2d2' },
  CIRCLE: {
    stroke: '#6409d3',
    text: '#252525',
    secondaryText: 'black',
  },
  ACTION_BUTTON: {
    color: 'black',
  },
});

import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

import {
  getAuth,
  onAuthStateChanged,
  sendEmailVerification,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  updateProfile,
  reauthenticateWithCredential,
  User,
  sendPasswordResetEmail,
} from 'firebase/auth';
import { httpsCallable, getFunctions } from 'firebase/functions';
import axios from 'axios';
import {
  createCheckoutSession,
  getProducts as getStripeProducts,
  getStripePayments,
  getCurrentUserPayments,
  getCurrentUserSubscriptions,
  onCurrentUserSubscriptionUpdate,
  Product,
  SubscriptionSnapshot,
} from '@stripe/firestore-stripe-payments';
import {
  clearCookieMeta,
  CookieMeta,
  setCookieMeta,
} from '../storage/cookie-meta-storage';
import { Context } from '../shared/types';
import ResWithMessage from '../api/responses/ResWithMessage';

const firebaseConfig = {
  apiKey: 'AIzaSyAcYwOuzx5zvRsnOHkZqCUnEZeiyYwduM8',
  authDomain: 'sharktank-9b320.firebaseapp.com',
  projectId: 'sharktank-9b320',
  storageBucket: 'sharktank-9b320.appspot.com',
  messagingSenderId: '1094242760392',
  appId: '1:1094242760392:web:dd406ec70b793e1b2d57fd',
  measurementId: 'G-CP5YTC7102',
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

if (typeof window !== 'undefined') {
  const analytics = getAnalytics(app);
}

const auth = getAuth();
const functions = getFunctions(app);

export const logout = async () => {
  const idTokenResult = await auth.currentUser?.getIdTokenResult(true);
  const API = '/api/auth/authorization/logout';

  if (!idTokenResult.token) {
    throw new Error();
  }

  const apiRes = await axios.get<ResWithMessage>(
    process.env.NODE_ENV === 'development'
      ? `http://localhost:8081${API}`
      : `https://api.dataabyss.ai${API}`,
    {
      headers: { Authorization: idTokenResult.token },
      withCredentials: true,
    },
  );

  await clearCookieMeta();

  if (apiRes.status !== 200) {
    throw new Error();
  }
  return signOut(auth);
};

async function authorize(token) {
  const API = '/api/auth/authorization/cookie';

  try {
    const apiRes = await axios.get<CookieMeta>(
      process.env.NODE_ENV === 'development'
        ? `http://localhost:8081${API}`
        : `https://api.dataabyss.ai${API}`,
      {
        headers: { Authorization: token },
        withCredentials: true,
      },
    );
    await setCookieMeta(apiRes.data);
  } catch (e) {
    // this logouts only for development environment for debugging purposes
    if (process.env.NODE_ENV !== 'development') {
      // time is set for a smoother disappearance of logged in icon inside <AppNavigation />
      setTimeout(async () => {
        await signOut(auth);
      }, 500);
    }
    throw new Error(e);
  }
}

export const login = async (email: string, password: string) =>
  signInWithEmailAndPassword(auth, email, password).then(async (res) => {
    const token = await res.user.getIdToken();

    await authorize(token);

    /*  if (!res.user.emailVerified) {
      await sendEmailVerification(res.user);
      // eslint-disable-next-line no-throw-literal
      throw { code: 'auth/email-not-verified' };
    } */

    return res;
  });

export const getUser = (): User | null => auth.currentUser;

export async function getIdToken() {
  return new Promise((resolve, reject) => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        resolve(user.getIdToken());
      } else {
        resolve('');
      }
    });
  });
}

export function register(email: string, password: string, displayName: string) {
  return createUserWithEmailAndPassword(auth, email, password).then(
    async (userCredential) => {
      await updateProfile(userCredential.user, { displayName });

      const token = await userCredential.user.getIdToken();

      await authorize(token);
    },
  );
}

const payments = getStripePayments(app, {
  productsCollection: 'products',
  customersCollection: 'customers',
});

const URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3000'
    : 'https://app.dataabyss.ai';

export const Stripe = {
  getProducts: async (): Promise<Product[]> => {
    const payments = getStripePayments(app, {
      productsCollection: 'products',
      customersCollection: 'customers',
    });

    const products = await getStripeProducts(payments, {
      includePrices: true,
      activeOnly: true,
    });

    return products.filter((product) => product.prices?.length > 0);
  },
  createCheckoutSession: async (
    role: string,
    priceId: string,
    context?: Context,
    trial?: boolean,
    cancelPath?: string,
    successPath?: string,
    quantity?: number,
    promotionCode?: string,
  ) => {
    const testPrices = {
      standard: 'price_1KiMYVHOzKdcpPCl6vMmLVTm',
      premium: 'price_1KiMdYHOzKdcpPClY1AOyWQb',
    };

    const session = await createCheckoutSession(payments, {
      allow_promotion_codes: true,
      price:
        process.env.NODE_ENV === 'development' ? testPrices[role] : priceId,
      trial_from_plan: Boolean(trial),
      success_url: successPath ? `${URL}${successPath}` : `${URL}/home`,
      cancel_url: cancelPath
        ? `${URL}${cancelPath}`
        : `${URL}/payments/products?context=${context}`,
      quantity,
      promotion_code: promotionCode,
    });
    if (typeof window !== 'undefined') {
      window.location.assign(session.url);
    }
  },
  didUserPay: async (): Promise<boolean> => {
    const userPayments = await getCurrentUserPayments(payments);

    return userPayments?.length > 0;
  },
  getCurrentPlan: async () => getCurrentUserSubscriptions(payments),
  onPlanUpdate: async (callback: (snapshot: SubscriptionSnapshot) => void) =>
    onCurrentUserSubscriptionUpdate(payments, callback),
  getCustomerPortal: async (pathname: string) => {
    const functionRef = httpsCallable(
      functions,
      'ext-firestore-stripe-payments-createPortalLink',
    );

    const { data } = await functionRef({
      returnUrl: `${URL}${pathname}`,
    });

    if (typeof window !== 'undefined') {
      // @ts-ignore
      window.location.assign(data?.url);
    }
  },
};

export const listenToUserState = (callback: (user: User) => void) => {
  onAuthStateChanged(auth, (user) => {
    callback(user);
  });
};

export async function isAdmin() {
  return auth.currentUser
    ?.getIdTokenResult(true)
    .then((idTokenResult) => Boolean(idTokenResult.claims.admin))
    .catch(() => {});
}

export async function isClaimExists(claim: string): Promise<boolean> {
  const isAuthorized = (resolve, reject) => {
    auth.currentUser
      ?.getIdTokenResult()
      .then((idTokenResult) => {
        resolve(Boolean(idTokenResult.claims[claim]));
      })
      .catch((error) => {
        reject(error);
      });
  };

  return new Promise((resolve, reject) => {
    if (!auth.currentUser) {
      setTimeout(() => {
        isAuthorized(resolve, reject);
      }, 1000);
    } else {
      isAuthorized(resolve, reject);
    }
  });
}

export async function resetPassword(email: string) {
  return sendPasswordResetEmail(auth, email, {
    url: 'https://dataabyss.ai/auth/login',
  });
}

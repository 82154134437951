import { FC, useEffect, useState } from 'react';
import { ClusterHealthResponse } from '@models/EsTypes';
import {
  Box,
  Fade,
  IconButton,
  IconButtonProps,
  Modal,
  Typography,
} from '@mui/material';
import { CloudCircle } from '@mui/icons-material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { EsHealthApi } from '../../api/health/es';
import { Paper } from '../elements/Paper';

interface EsHealthProps {
  iconButtonProps?: IconButtonProps;
}

const useStyles = makeStyles(() =>
  createStyles({
    modal: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 500,
      display: 'flex',
      justifyContent: 'center',
    },
    textContainer: {
      display: 'flex',
      gap: 8,
    },
    title: {
      fontWeight: 'bold',
      color: '#ccc9c9',
    },
  }),
);

export const EsHealth: FC<EsHealthProps> = ({ iconButtonProps }) => {
  const classes = useStyles();

  const [health, setHealth] = useState<ClusterHealthResponse>();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    EsHealthApi.getEsHealth().then((res) => {
      setHealth(res.data);
    });
  }, []);

  return (
    <div>
      <Fade in={Boolean(health)}>
        <IconButton {...iconButtonProps} onClick={handleOpen} size="large">
          <CloudCircle
            style={{
              color: health?.status === 'yellow' ? 'orange' : health?.status,
              fontSize: 30,
            }}
          />
        </IconButton>
      </Fade>
      <Modal open={open} onClose={handleClose}>
        <Box className={classes.modal}>
          <Paper
            sx={{
              padding: 10,
              display: 'flex',
              gap: 2,
              flexDirection: 'column',
              color: 'white',
              backgroundColor: '#282a36',
            }}
          >
            <Typography variant={'h6'}>ES Cluster Information</Typography>
            <div className={classes.textContainer}>
              <Typography className={classes.title}>Status:</Typography>
              <Typography style={{ color: health?.status }}>
                {health?.status}
              </Typography>
            </div>
            <div className={classes.textContainer}>
              <Typography className={classes.title}>Timed Out:</Typography>
              <Typography>{health?.timed_out ? 'true' : 'false'}</Typography>
            </div>
            <div className={classes.textContainer}>
              <Typography className={classes.title}>Nodes:</Typography>
              <Typography>{health?.number_of_nodes}</Typography>
            </div>
            <div className={classes.textContainer}>
              <Typography className={classes.title}>Data Nodes:</Typography>
              <Typography>{health?.number_of_data_nodes}</Typography>
            </div>
            <div className={classes.textContainer}>
              <Typography className={classes.title}>
                Active Primary Shards:
              </Typography>
              <Typography>{health?.active_primary_shards}</Typography>
            </div>
            <div className={classes.textContainer}>
              <Typography className={classes.title}>Active Shards:</Typography>
              <Typography>{health?.active_shards}</Typography>
            </div>
            <div className={classes.textContainer}>
              <Typography className={classes.title}>
                Active Shards Percentage:
              </Typography>
              <Typography>{`${health?.active_shards_percent_as_number}%`}</Typography>
            </div>
            <div className={classes.textContainer}>
              <Typography className={classes.title}>
                Relocating Shards:
              </Typography>
              <Typography>{health?.relocating_shards}</Typography>
            </div>
            <div className={classes.textContainer}>
              <Typography className={classes.title}>
                Initializing Shards:
              </Typography>
              <Typography>{health?.initializing_shards}</Typography>
            </div>
            <div className={classes.textContainer}>
              <Typography className={classes.title}>
                Unassigned Shards:
              </Typography>
              <Typography>{health?.unassigned_shards}</Typography>
            </div>
            <div className={classes.textContainer}>
              <Typography className={classes.title}>
                Delayed Unassigned Shards:
              </Typography>
              <Typography>{health?.delayed_unassigned_shards}</Typography>
            </div>
            <div className={classes.textContainer}>
              <Typography className={classes.title}>Pending Tasks:</Typography>
              <Typography>{health?.number_of_pending_tasks}</Typography>
            </div>
            <div className={classes.textContainer}>
              <Typography className={classes.title}>Inflight Fetch:</Typography>
              <Typography>{health?.number_of_in_flight_fetch}</Typography>
            </div>
            <div className={classes.textContainer}>
              <Typography className={classes.title}>
                Tasks Max Waiting In Queue in Millis:
              </Typography>
              <Typography>
                {health?.task_max_waiting_in_queue_millis}
              </Typography>
            </div>
          </Paper>
        </Box>
      </Modal>
    </div>
  );
};

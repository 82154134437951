import { FC, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Chip,
  darken,
  Dialog,
  lighten,
  Stack,
  Typography,
} from '@mui/material';
// eslint-disable-next-line import/no-cycle
import { Icon } from '@iconify/react';
import SearchInput from '../SearchInput';

export interface InputModalProps {
  open: boolean;
  onClose: () => void;
}

export const InputModal: FC<InputModalProps> = () => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const onSearchDone = () => {
    setOpen(false);
  };

  useEffect(() => {
    function handleKeyDown(e: KeyboardEvent) {
      if (
        (typeof window !== 'undefined' &&
        navigator?.platform?.toLowerCase()?.includes('mac')
          ? e.metaKey
          : e.ctrlKey) &&
        e.key === 'k'
      ) {
        e.preventDefault();
        e.stopPropagation();

        setOpen((currentValue) => !currentValue);
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <>
      <Button
        variant={'outlined'}
        sx={{
          textAlign: 'left',
          backgroundColor: '#f3f6f9',
          width: 350,
          borderRadius: 50,
          justifyContent: 'space-between',
          color: '#7a7a7a',
          borderColor: darken('#f3f6f9', 0.4),
        }}
        startIcon={
          <Icon
            icon={'iconamoon:search-thin'}
            style={{ fontSize: 25, color: lighten('#0e63cb', 0.2) }}
          />
        }
        onClick={() => {
          setOpen(true);
        }}
        endIcon={
          <Stack
            direction={'row'}
            sx={{
              color: 'black',
              backgroundColor: 'white',
              borderRadius: '5px 5px 5px 5px',
              px: 0.5,
              borderColor: '#363636',
              borderStyle: 'solid',
            }}
          >
            <Typography>
              {typeof window !== 'undefined' &&
              navigator?.platform?.toLowerCase()?.includes('mac')
                ? '⌘'
                : 'Ctrl'}
            </Typography>
            <Typography>K</Typography>
          </Stack>
        }
      >
        <Typography
          sx={{
            px: 1,
            textTransform: 'none',
            textAlign: 'left',
            width: '100%',
          }}
        >
          Search...
        </Typography>
      </Button>
      <Dialog onClose={handleClose} open={open} maxWidth={'md'}>
        <Box sx={{ width: 700 }}>
          <SearchInput flat onSearchDone={onSearchDone} />
        </Box>
      </Dialog>
    </>
  );
};

import { FC, useEffect, useState } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import { Icon } from '@iconify/react';

export type LogicalOperator = 'AND' | 'OR' | 'NOT';
const logicalOperators: LogicalOperator[] = ['OR', 'AND', 'NOT'];

export interface OperatorMenuProps {
  onOperatorSelected(operator: LogicalOperator): void;
  disabledOperators?: LogicalOperator[];
}

export const OperatorMenu: FC<OperatorMenuProps> = ({
  onOperatorSelected,
  disabledOperators = [],
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const [selectedOperator, setSelectedOperator] =
    useState<LogicalOperator>('OR');

  const reset = () => {
    setAnchorEl(null);
  };

  const handleClose = (operator: LogicalOperator) => {
    setSelectedOperator(operator);
    onOperatorSelected(operator);
    reset();
  };

  return (
    <>
      <Button
        variant={'outlined'}
        sx={{
          textTransform: 'none',
          borderRadius: 0,
          mr: 1,
          ml: 1,
          width: 70,
          color: 'gray',
          '&:hover': {
            borderColor: '#631da8',
          },
        }}
        onClick={handleClick}
        endIcon={
          <Icon
            icon={open ? 'iconoir:nav-arrow-down' : 'iconoir:nav-arrow-up'}
            fontSize={25}
          />
        }
      >
        {selectedOperator}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={reset}
        sx={{ zIndex: 2200 }}
      >
        {logicalOperators
          ?.filter((operator) => !disabledOperators?.includes(operator))
          .map((field) => (
            <MenuItem
              key={`${field}-operator`}
              onClick={() => handleClose(field)}
            >
              {field}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};

import { FC } from 'react';
import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
} from '@mui/material';

interface ButtonProps extends MuiButtonProps {
  name?: string;
}

export const Button: FC<ButtonProps> = ({ name = '', ...rest }) => (
  <MuiButton data-testid={`button:${name}`} {...rest} />
);

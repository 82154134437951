import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Box, Divider, useMediaQuery } from '@mui/material';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useState } from 'react';
import AppNavigation from '../AppNavigation';
import SearchInput from '../search/input/SearchInput';
import { InputModal } from '../search/input/InputModal';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    appbar: {
      background: theme.palette.background.default,
      paddingTop: 2.5,
      paddingBottom: 2.5,
      zIndex: theme.zIndex.drawer + 1,
    },
    toolbar: {
      display: 'flex',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      },
    },
  }),
);

interface AppBarProps {
  loggedIn?: boolean;
  publicDemo?: boolean;
}

export default function Appbar(props: AppBarProps) {
  const classes = useStyles();
  const router = useRouter();

  const isDownXSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );

  const [openModal, setOpenModal] = useState<boolean>(false);

  const onCloseModal = () => {
    setOpenModal(!openModal);
  };

  const searchInput = (
    <>
      <InputModal open={openModal} onClose={onCloseModal} />
    </>
  );

  return (
    <div className={classes.root}>
      <AppBar position="sticky" elevation={0} className={classes.appbar}>
        <Toolbar className={classes.toolbar}>
          <div style={{ flexGrow: 1 }} />
          <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
            <Box
              sx={{ py: 1, cursor: 'pointer' }}
              onClick={() => {
                router.push('/home');
              }}
            >
              <Image
                src={'/images/ParallaxLogo_DarkBlue_CMYK.png'}
                alt="Logo Image"
                width={467 * 0.27}
                height={247 * 0.27}
                priority
                quality={95}
              />
            </Box>
            <div style={{ flexGrow: 1 }} />
            <span>{!isDownXSmallScreen ? searchInput : null}</span>
            <div style={{ flexGrow: 1 }} />
            <AppNavigation
              loggedIn={props.loggedIn}
              publicDemo={props.publicDemo}
            />
          </div>
          {/* reverses order of searchInput and <AppNavigation/> for responsive design. */}
          <>{isDownXSmallScreen ? searchInput : null}</>
        </Toolbar>
      </AppBar>
      <Divider style={{ backgroundColor: '#ececec', height: 0.5 }} />
    </div>
  );
}

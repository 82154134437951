import { useContext } from 'react';
import { Theme } from '@mui/material/styles';
import { useMediaQuery, Box, BoxProps } from '@mui/material';
import { useRouter } from 'next/router';
import SwipeableTemporaryDrawer from './SwipeableTemporaryDrawer';
import { EsHealth } from './EsHealth';
import { NavButton } from './elements/NavButton';
import { NavMenu } from './elements/NavMenu';
import { ProfileMenu } from './modules/ProfileMenu';
import { AccessRestrictor } from './elements/AccessRestrictor';
import { PlanContext } from '../Context/PlanContext';
import { Plan } from '../shared/types';

interface Props {
  /*
   * style applied to second <Box/>
   * */
  rightStyle?: BoxProps;
  homepage?: boolean;
  loggedIn?: boolean;
  publicDemo?: boolean;
}

export default function AppNavigation(props: Props) {
  const router = useRouter();

  const isDownSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  );

  const plan = useContext(PlanContext);

  if (props.homepage) {
    return <></>;
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      {router.pathname === '/home' ? (
        <Box sx={{ p: 3 / 8 }}>
          <EsHealth />
        </Box>
      ) : (
        <div />
      )}
      <Box
        sx={{
          float: 'right',
          ...props.rightStyle,
          mr: !isDownSmallScreen && router.pathname === '/home' ? 1.6 : null,
        }}
      >
        {isDownSmallScreen ? (
          <Box sx={{ mt: 1.5 }}>
            <SwipeableTemporaryDrawer
              loggedIn={props.loggedIn}
              anchor={'right'}
              iconButtonProps={{
                sx: {
                  mb: 1,
                  backgroundColor: 'secondary.main',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: 'secondary.dark',
                  },
                },
              }}
            />
            {!props.publicDemo && <ProfileMenu />}
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 3.5,
              py: 2,
              pl: 1,
            }}
          >
            <NavButton href={'/home'} title={'Home'} />
            <NavMenu
              title={'Capabilities'}
              alternateHrefs={[
                'https://dataabyss.ai/marketplace',
                '/capabilities/elliot',
                '/capabilities/chatbot',
                '/capabilities/strategic-competition',
                '/capabilities/affiliates',
                '/capabilities/marketplace',
                '/capabilities/graph',
              ]}
            >
              <NavButton
                borderlessLinkButtonProps={{
                  markColor: 'white',
                  external: true,
                }}
                href={'https://dataabyss.ai/marketplace'}
                title={'Marketplace'}
                padded
              />
              <NavButton
                borderlessLinkButtonProps={{
                  markColor: 'white',
                  external: true,
                }}
                href={'/capabilities/elliot'}
                title={'Elliot'}
                padded
              />
              {/* <NavButton
                borderlessLinkButtonProps={{
                  markColor: 'white',
                  external: true,
                }}
                href={'/capabilities/chatbot'}
                title={'Mandarin Matrix'}
                padded
              /> */}
              <NavButton
                borderlessLinkButtonProps={{ markColor: 'white' }}
                href={'/capabilities/strategic-competition'}
                title={'Strategic Competition'}
                padded
              />
              <NavButton
                borderlessLinkButtonProps={{ markColor: 'white' }}
                href={'/capabilities/graph'}
                title={'Graph'}
                padded
              />
              <NavButton
                borderlessLinkButtonProps={{ markColor: 'white' }}
                href={'/capabilities/affiliates'}
                title={'Affiliates'}
                padded
              />
            </NavMenu>
            <NavMenu title={'Tools'} alternateHrefs={['/tools/kibana']}>
              <AccessRestrictor
                restrict={props.publicDemo ? true : plan !== Plan.Premium}
                currentPlan={plan}
                publicDemo={props.publicDemo}
              >
                <NavButton
                  borderlessLinkButtonProps={{ markColor: 'white' }}
                  href={'/tools/kibana'}
                  title={'Kibana'}
                  padded
                />
              </AccessRestrictor>
            </NavMenu>
            <NavButton
              href={'https://dataabyss.ai/documentation'}
              title={'Documentation'}
              borderlessLinkButtonProps={{ external: true }}
            />
            {props.loggedIn && <ProfileMenu />}
          </Box>
        )}
      </Box>
    </Box>
  );
}

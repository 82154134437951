import { ChangeEvent, FC } from 'react';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { InputBase, InputBaseProps } from '../InputBase';

export type QueryCallback = (q: string) => void;

interface SearchInputProps {
  name?: string;
  inputProps?: InputBaseProps;
  queryCallback?: QueryCallback;
  debounceWait?: number;
  onEnterKeyDown?: (q: string) => void;
  immediateQueryCallback?: QueryCallback;
}

export const SearchInput: FC<SearchInputProps> = ({
  name = '',
  inputProps,
  queryCallback,
  debounceWait = 100,
  onEnterKeyDown,
  immediateQueryCallback,
}) => {
  const asyncFunctionDebounced = AwesomeDebouncePromise(
    (q) => queryCallback(q),
    debounceWait,
  );

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (immediateQueryCallback) {
      immediateQueryCallback(event.target.value);
    }
    if (queryCallback) {
      asyncFunctionDebounced(event.target.value);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (onEnterKeyDown) {
        onEnterKeyDown(event.target.value);
      }
    }
  };

  return (
    <InputBase
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      placeholder={'Search...'}
      {...inputProps}
      name={`search:${name}`}
    />
  );
};

import { AxiosInstance, AxiosResponse } from 'axios';
import { ParsedUrlQuery } from 'querystring';
import STDataAutocompleteResult from '@models/STDataAutocompleteResult';
import { PublicationsSearchResult } from '@models/PublicationsSearchResults';
import { CriteriaData } from '@models/CriteriaData';
// eslint-disable-next-line camelcase
import { Author_SearchResult } from '@models/Author_SearchResult';
import { ImpactLevel } from '@models/IImpact';
import AxiosClientBuilder, { ApiName } from '../../AxiosClientBuilder';

export type RiskLevelType = 'Extreme' | 'Very High' | 'High' | 'Medium' | 'Low';

export class SearchApi {
  private static ROOT = '/search';

  private static searchClient: AxiosInstance = new AxiosClientBuilder()
    .setApiName(ApiName.Publications)
    .setPrivate(true)
    .build();

  /**
   *
   * @param q the q by which the search matches affiliation names
   */
  static autocomplete(
    q: string,
  ): Promise<AxiosResponse<STDataAutocompleteResult[]>> {
    return this.searchClient.get<STDataAutocompleteResult[]>(
      `${this.ROOT}/autocomplete/`,
      {
        params: {
          q,
        },
      },
    );
  }

  static generalSearch(
    criteria: CriteriaData[],
    offset: number,
    {
      year,
      risk,
      impact,
      // eslint-disable-next-line camelcase
      publication_date,
    }: ParsedUrlQuery,
    size = 25,
  ): Promise<AxiosResponse<PublicationsSearchResult>> {
    return this.searchClient.get<PublicationsSearchResult>(`${this.ROOT}/`, {
      params: {
        criteria: JSON.stringify(criteria),
        offset,
        size,
        year,
        risk,
        impact,
        // eslint-disable-next-line camelcase
        publication_date,
      },
    });
  }

  static authorSearch(
    criteria: CriteriaData[],
    maxDate: string,
    minDate: string,
    risk?: RiskLevelType,
    impact?: ImpactLevel,
    // eslint-disable-next-line camelcase
  ): Promise<AxiosResponse<Author_SearchResult[]>> {
    // eslint-disable-next-line camelcase
    return this.searchClient.get<Author_SearchResult[]>(
      `${this.ROOT}/author_`,
      {
        params: {
          criteria: JSON.stringify(criteria),
          maxDate,
          minDate,
          risk,
          impact,
        },
      },
    );
  }
}

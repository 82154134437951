import { useState, createContext, useContext, FC } from 'react';
import {
  CircularProgress,
  CircularProgressProps,
  Modal,
  Typography,
} from '@mui/material';

interface LoadingModalProps {
  circularProgressProps?: CircularProgressProps;
}

type ContextType = {
  showModal: (message?: string) => void;
  hideModal: () => void;
};

const initialState: ContextType = {
  showModal: () => {},
  hideModal: () => {},
};

const LoadingModalContext = createContext(initialState);
export const useLoadingModalContext = () => useContext(LoadingModalContext);

export const LoadingModal: FC<LoadingModalProps> = ({
  children,
  circularProgressProps,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('Loading...');

  const showModal = (thisMessage?: string) => {
    setOpen(true);

    if (thisMessage) {
      setMessage(thisMessage);
    }
  };

  const hideModal = () => {
    setOpen(false);
  };

  return (
    <LoadingModalContext.Provider value={{ showModal, hideModal }}>
      <Modal
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        open={open}
      >
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              padding: 100,
              borderRadius: 5,
              backgroundColor: 'white',
              opacity: 0.9,
              boxShadow:
                'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px',
              gap: 16,
              color: '#343434',
            }}
          >
            <CircularProgress
              size={75}
              style={{ color: '#343434' }}
              {...circularProgressProps}
            />
            <Typography variant={'h4'}>{message}</Typography>
          </div>
        </>
      </Modal>
      {children}
    </LoadingModalContext.Provider>
  );
};

import { AxiosInstance, AxiosResponse } from 'axios';
import { CriteriaData } from '@models/CriteriaData';
import { HtmlSearchResults } from '@models/HtmlPage';
import { ParsedUrlQuery } from 'querystring';
import AxiosClientBuilder, { ApiName } from '../../AxiosClientBuilder';

export class SearchApi {
  private static ROOT = '/search';

  private static searchClient: AxiosInstance = new AxiosClientBuilder()
    .setApiName(ApiName.Images)
    .setPrivate(true)
    .build();

  static fetchImages(
    criteria: CriteriaData[],
    offset: number,
    {
      // eslint-disable-next-line camelcase
      publication_date,
    }: ParsedUrlQuery,
  ): Promise<AxiosResponse<HtmlSearchResults>> {
    return this.searchClient.get<HtmlSearchResults>(`${this.ROOT}/`, {
      params: {
        criteria: JSON.stringify(criteria),
        // eslint-disable-next-line camelcase
        publication_date,
        offset,
      },
    });
  }

  static autocomplete(q: string): Promise<AxiosResponse<string[]>> {
    return this.searchClient.get<string[]>(`${this.ROOT}/autocomplete/`, {
      params: {
        q,
      },
    });
  }
}

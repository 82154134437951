import axios, { AxiosResponse } from 'axios';
import IOpenAlexInstitution from '@models/IOpenAlexInstitution';

export class OpenAlexInstitutionApi {
  private static ROOT = 'https://api.openalex.org/institutions';

  static fetchInstitutions(
    displayName: string,
  ): Promise<AxiosResponse<{ results: IOpenAlexInstitution[] }>> {
    return axios.get<{ results: IOpenAlexInstitution[] }>(`${this.ROOT}`, {
      params: {
        filter: `display_name.search:${displayName}`,
      },
    });
  }
}
